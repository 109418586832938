import { DefaultRepository } from '@/services/base'
import { UserEntity } from '@/services/entities/user'
import request from '@/plugin/request'

export default class SettingRepository extends DefaultRepository<UserEntity> {
  constructor() {
    super('/bo/api/v1/settings')
  }

  async getApiYoutubeKey(params: Record<string, any>) {
    try {
      const result = await request.get(`/bo/api/v1/api_keys`, { params })
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async postApiYoutubeKey(data: Record<string, any>) {
    try {
      const result = await request.post(`/bo/api/v1/api_keys`, data)
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
