import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "s2e_report_detail" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoStreamer = _resolveComponent("InfoStreamer")!
  const _component_table_admin = _resolveComponent("table-admin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InfoStreamer),
    _createVNode(_component_table_admin, {
      module: "report",
      columns: _ctx.columns,
      ref: "table",
      scroll: { x: 0 },
      store: {
        state: 'queryS2eReportDetail',
        module: 'report',
        action: 'searchS2eReportDetail'
      }
    }, {
      bodyCell: _withCtx(({ column, record }) => [
        (column.key === 'date')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (record.category === 'monthly')
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$filters.formatMonthOfYear(record.date)), 1))
                : (record.category === 'weekly')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString('Week of ' + _ctx.$filters.formatDate(record.date, 'DD/MM/YYYY')), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$filters.formatDate(record.date, 'DD/MM/YYYY')), 1))
            ], 64))
          : _createCommentVNode("", true),
        (column.key === 'ste_transaction')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (record.category === 'weekly')
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(record.ste_transaction.claimed_at ? _ctx.$filters.formatUSDT(record.total_earnings >= 0 ? record.total_earnings : 0) : 0), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_6, "N/A"))
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columns"])
  ]))
}