import "core-js/modules/es.array.push.js";
const {
  mapActions
} = createNamespacedHelpers('auth');
export default defineComponent({
  name: 'Login',
  components: {},
  data() {
    return {
      form: {
        username: '',
        password: '',
        remember: false
      },
      loading: false
    };
  },
  methods: {
    ...mapActions(['login']),
    async onSubmit() {
      try {
        this.loading = true;
        await this.login(this.form);
        this.$router.push('/');
      } catch (e) {}
      this.loading = false;
    }
  }
});