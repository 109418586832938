import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "pool-bet" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_tabs, {
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _ctx.onActive
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
          return (_openBlock(), _createBlock(_component_a_tab_pane, {
            key: tab.key,
            tab: tab.title
          }, {
            default: _withCtx(() => [
              (_ctx.activeKey == tab.key)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(tab.component), {
                    key: 0,
                    ref_for: true,
                    ref: tab.key
                  }, null, 512))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["tab"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["activeKey", "onUpdate:activeKey"])
  ]))
}