import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_admin = _resolveComponent("table-admin")!

  return (_openBlock(), _createBlock(_component_table_admin, {
    module: "report",
    columns: _ctx.columns,
    ref: "template",
    scroll: { x: 0 },
    store: {
      state: 'queryMonthlyReport',
      module: 'report',
      action: 'searchMonthlyReport'
    },
    class: "monthly_report"
  }, {
    bodyCell: _withCtx(({ column, record }) => [
      (column.key === 'date')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(record.date.substr(0, 7)), 1)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["columns"]))
}