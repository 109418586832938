import TableAdmin from '@/components/share/TableAdmin.vue';
import AddApiYoutubeKey from '@/modules/settings/components/AddApiYoutubeKey.vue';
export default defineComponent({
  name: 'User',
  components: {
    AddApiYoutubeKey,
    TableAdmin
  },
  data() {
    return {
      columns: [{
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
      }, {
        title: 'key',
        dataIndex: 'key',
        key: 'key'
      }, {
        title: 'type',
        dataIndex: 'type',
        key: 'type'
      }, {
        title: 'created at',
        dataIndex: 'created_at',
        key: 'created_at',
        date: true
      }]
    };
  }
});