import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9edc0e30"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["xlink:href"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.type == 'svg')
      ? (_openBlock(), _createElementBlock("svg", {
          key: 0,
          class: _normalizeClass(_ctx.className()),
          style: _normalizeStyle(_ctx.style),
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("use", {
            "xlink:href": '#icon-' + _ctx.name
          }, null, 8, _hoisted_1)
        ], 6))
      : _createCommentVNode("", true),
    (_ctx.type == 'png')
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _ctx.getSrc(),
          alt: "",
          style: _normalizeStyle(_ctx.style)
        }, null, 12, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}