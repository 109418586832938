import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_table_admin = _resolveComponent("table-admin")!

  return (_openBlock(), _createBlock(_component_table_admin, {
    module: "report",
    columns: _ctx.columns,
    ref: "template",
    scroll: { x: 0 },
    store: {
      state: 'queryS2eReport',
      module: 'report',
      action: 'searchS2eReport'
    },
    class: "s2e_report"
  }, {
    bodyCell: _withCtx(({ column, record }) => [
      (column.key === 'streamer_name')
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: `/reports/s2e-report/${record.url_name}`
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.streamer_name), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["columns"]))
}