import { MutationTree } from 'vuex'
import { IAuth } from '.'

const mutations: MutationTree<IAuth> = {
  SET_STATE(state, payload) {
    state.access_token = payload.access_token
    state.refresh_token = payload.refresh_token
    state.username = payload.username
  },
  SET_TOKEN(state, token) {
    state.access_token = token
  }
}
export default mutations
