<template>
  <div class="container">
    <div class="header d-flex">
      <BaseIcon name="logo" type="png"></BaseIcon>
      <div class="support"></div>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AuthLayout',
    components: {}
  }
</script>

<style scoped lang="scss">
  .container {
    position: relative;
    padding: 20px 200px;
    width: 100vw;
    min-height: 100vh;
    background-image: url('@/assets/image/png/background.png');
    background-size: cover;

    .content {
      margin-top: 120px;
    }

    .header {
      position: sticky;
      top: 0;
      z-index: 200;
      transition: background 0.3s linear;
      height: 84px;
      width: 100%;

      .support {
        font-weight: bold;
        font-size: 16px;
        color: var(--text-primary-2);
        cursor: pointer;
      }
    }
  }
</style>
