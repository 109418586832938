import TableAdmin from '@/components/share/TableAdmin.vue';
export default defineComponent({
  name: 'MonthlyReport',
  components: {
    TableAdmin
  },
  data() {
    return {
      columns: [{
        title: this.$t('common.month'),
        dataIndex: 'date',
        width: '120px',
        key: 'date'
      }, {
        title: this.$t('module.report.daily.total_viewers_count'),
        dataIndex: 'total_viewer_count',
        key: 'total_viewer_count',
        number: true
      }, {
        title: this.$t('module.report.daily.peak_viewers_count'),
        dataIndex: 'peak_viewer_count',
        key: 'peak_viewer_count',
        number: true
      }, {
        title: this.$t('module.report.daily.total_view_time'),
        dataIndex: 'total_view_time',
        key: 'total_view_time',
        secondToTime: true
      }, {
        title: this.$t('module.report.daily.total_djt_claimed_viewers'),
        dataIndex: 'total_djt_claimer_count',
        key: 'total_djt_claimer_count',
        number: true
      }, {
        title: this.$t('module.report.daily.total_djt_claim_count'),
        dataIndex: 'total_djt_claim_count',
        key: 'total_djt_claim_count',
        number: true
      }, {
        title: this.$t('module.report.daily.total_djt_amount_claimed'),
        dataIndex: 'total_djt_amount_claimed',
        key: 'total_djt_amount_claimed',
        number: true
      }, {
        title: this.$t('module.report.daily.streamer_count'),
        dataIndex: 'streamer_count',
        key: 'streamer_count',
        number: true
      }, {
        title: this.$t('module.report.daily.stream_count'),
        dataIndex: 'stream_count',
        key: 'stream_count',
        number: true
      }]
    };
  },
  methods: {}
});