import TableAdmin from '@/components/share/TableAdmin.vue';
export default defineComponent({
  name: 'StreamerList',
  components: {
    TableAdmin
  },
  data() {
    return {
      columns: [{
        title: this.$t('module.stream.create_streamer.djt_per_hour'),
        dataIndex: 'djt_amount_per_hour',
        number: true
      }, {
        title: this.$t('module.stream.5_min_promotion'),
        dataIndex: 'djt_amount_per_five_minutes',
        number: true
      }, {
        title: this.$t('module.stream.10_min_promotion'),
        dataIndex: 'djt_amount_per_ten_minutes',
        number: true
      }, {
        title: this.$t('module.stream.15_min_promotion'),
        dataIndex: 'djt_amount_per_fifteen_minutes',
        number: true
      }, {
        title: this.$t('common.updated_by'),
        dataIndex: 'last_updated_by'
      }, {
        title: this.$t('common.updated_on'),
        dataIndex: 'updated_at',
        datetimeSecond: true
      }]
    };
  }
});