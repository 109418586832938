import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseTable from '@/components/base/BaseTable.vue'
import BaseFormValidator from '@/components/base/BaseFormValidator.vue'
import BaseModal from '@/components/base/BaseModal.vue'
import BaseConfirm from '@/components/base/BaseConfirm.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'
import BaseDate from '@/components/base/BaseDate.vue'
import BaseUpload from '@/components/base/BaseUpload.vue'
import type { Component } from 'vue'

const components: Record<string, Component> = {
  BaseIcon,
  BaseTable,
  BaseModal,
  BaseFormValidator,
  BaseConfirm,
  BaseSelect,
  BaseDate,
  BaseUpload
}

export default components
