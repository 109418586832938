import StreamerRepository from '@/services/repositories/streamer';
import BaseUpload from '@/components/base/BaseUpload.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import GetChannelYT from '@/modules/stream/components/GetChannelYT.vue';
import { message } from 'ant-design-vue';
import { categoryGame } from '@/utils/common';
const apiStreamer = new StreamerRepository();
export default defineComponent({
  components: {
    BaseSelect,
    BaseUpload,
    GetChannelYT
  },
  computed: {
    affiliates() {
      return this.$store.state.stream.affiliateList;
    }
  },
  data() {
    return {
      categoryGame,
      loading: false,
      visible: false,
      validateMessages: {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!'
        },
        number: {
          range: '${label} must be between ${min} and ${max}'
        }
      },
      errorClass: false,
      streamer: reactive({
        twitch_login: '',
        youtube_channel_id: '',
        default_category: null,
        streamer_name: '',
        profile_image_url: null,
        video_reward_id: null,
        stream_reward_id: null,
        status: 'enabled',
        description: '',
        is_partner: false,
        s2e_token_amount_per_hour: null,
        s2e_token_amount_per_hour_for_video: null,
        affiliate: null,
        affiliate_stream_reward_id: null,
        affiliate_video_reward_id: null
      })
    };
  },
  watch: {
    streamer: {
      deep: true,
      handler() {
        this.errorClass = !this.streamer.youtube_channel_id && !this.streamer.twitch_login;
        if (!this.streamer.youtube_channel_id) {
          this.streamer.default_category = null;
        }
      }
    }
  },
  methods: {
    async onCancel() {
      this.$refs.form.clearValidate();
    },
    async onSubmit() {
      if (!this.streamer.twitch_login && !this.streamer.youtube_channel_id) {
        message.error('Must enter at least 1 Twitch or Youtube ID');
        this.errorClass = true;
        return;
      }
      this.loading = true;
      //@ts-ignore
      this.$refs.form.validate().then(async valid => {
        await apiStreamer.create(valid);
        this.streamer = {
          twitch_login: '',
          youtube_channel_id: '',
          streamer_name: '',
          profile_image_url: null,
          video_reward_id: null,
          stream_reward_id: null,
          status: 'enabled',
          is_partner: false,
          default_category: null,
          description: '',
          s2e_token_amount_per_hour: null,
          s2e_token_amount_per_hour_for_video: null,
          affiliate: null,
          affiliate_stream_reward_id: null,
          affiliate_video_reward_id: null
        };
        this.visible = false;
        this.$emit('reloadPage');
        this.errorClass = false;
      }).catch(e => {});
      this.loading = false;
    },
    show() {
      this.errorClass = false;
      this.visible = true;
    }
  }
});