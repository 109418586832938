import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_form_item, _mergeProps(_ctx.$attrs, { label: _ctx.label }, _toHandlers(_ctx.$listeners), {
      class: "custom-validator",
      rules: _ctx.rules
    }), {
      extra: _withCtx(() => [
        _renderSlot(_ctx.$slots, "extra", {}, undefined, true)
      ]),
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 16, ["label", "rules"])
  ]))
}