import Card from '@/modules/report/components/Card.vue';
import _ from 'lodash';
export default defineComponent({
  components: {
    Card
  },
  computed: {
    ...mapState({
      streamer(state) {
        return _.get(state, 'report.streamer', {});
      }
    })
  }
});