import _ from 'lodash';
export default defineComponent({
  components: {},
  props: {
    defaultValue: {
      type: [String, Array],
      require: false,
      default: () => []
    },
    value: {
      type: [String, Number, Array],
      require: false,
      default: () => {
        return '';
      }
    },
    useLoadMore: {
      type: Object,
      default: () => {
        return {
          active: false,
          url: '',
          key: '',
          firstLoader: false,
          option: {
            value: 'id',
            title: 'name'
          }
        };
      }
    }
  },
  computed: {
    dataOption() {
      return this.$store.getters.getOptions({
        key: this.useLoadMore.key,
        filter: this.textSearch
      });
    },
    pagy() {
      return this.$store.getters.getPagyOptions({
        key: this.useLoadMore.key,
        filter: this.textSearch
      });
    },
    loading() {
      return this.pagy.page < this.pagy.pages;
    }
  },
  methods: {
    searchText(searchText) {
      this.handleSearch(this, searchText);
    },
    handleSearch: _.debounce((that, searchText) => {
      that.textSearch = searchText;
      if (searchText) {
        that.$store.dispatch('loadMoreOptionFilter', {
          key: that.useLoadMore.key,
          filter: that.textSearch
        });
      }
    }, 1000),
    loadMoreMethod: _.debounce(that => {
      if (that.useLoadMore.url) {
        if (that.textSearch) {
          that.$store.dispatch('loadMoreOptionFilter', {
            key: that.useLoadMore.key,
            filter: that.textSearch
          });
        } else {
          that.$store.dispatch('loadMoreOption', {
            key: that.useLoadMore.key
          });
        }
      }
      that.$emit('loadMore');
    }, 1000),
    handleChange() {
      this.searchText('');
      this.$nextTick(() => {
        try {
          this.$refs.select.blur();
        } catch (e) {}
      });
    },
    handleClick() {
      if (!isEmpty(document.querySelector('.rc-virtual-list-holder')) && this.useLoadMore.active) {
        document.querySelector('.rc-virtual-list-holder')?.addEventListener('scroll', this.handleScroll);
      }
    },
    handleScroll(e) {
      //@ts-ignore
      if (e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop <= 10) {
        this.loadMoreMethod(this);
      }
    }
  },
  unmounted() {
    if (!isEmpty(document.querySelector('.rc-virtual-list-holder')) && this.useLoadMore.active) {
      document.querySelector('.rc-virtual-list-holder')?.removeEventListener('scroll', this.handleScroll);
    }
  },
  async created() {
    await this.$store.dispatch('setInitDataSelect', this.useLoadMore);
    if (!isEmpty(this.defaultValue)) {
      this.$store.dispatch('loadMoreOption', {
        key: this.useLoadMore.key,
        defaultValue: this.defaultValue
      });
    }
    if (isEmpty(this.defaultValue) && this.useLoadMore.firstLoader) {
      this.$store.dispatch('loadMoreOption', {
        key: this.useLoadMore.key
      });
    }
  },
  data() {
    return {
      response: {},
      data: [],
      textSearch: ''
    };
  }
});