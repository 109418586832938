import { GetterTree } from 'vuex'
import { IUser } from '.'

const getters: GetterTree<IUser, any> = {
  videos: state => {
    const videos = {}
    state.user.videos.forEach((item: Record<string, any>) => {
      // @ts-ignore
      videos[item.id] = {
        title: item.title,
        game: item.game_name
      }
    })
    return videos
  },
  streams: state => {
    const streams = {}
    state.user.streams.forEach((item: Record<string, any>) => {
      // @ts-ignore
      streams[item.id] = {
        title: item.stream_title,
        game: item.game_name
      }
    })
    return streams
  }
}

export default getters
