import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { GroupFilterInterface, Filter } from '@/utils/interface/common'
import { PagyInterface } from '@/utils/interface/common'
import { defaultPagy } from '@/utils/common'
export interface IReport {
  queryS2eReport: GroupFilterInterface
  queryS2eReportDetail: GroupFilterInterface
  queryUserReport: GroupFilterInterface
  streamerUrlName: string | number
  streamer: string
  queryMonthlyReport: GroupFilterInterface
  reportDaily: {
    data: Record<string, any>[]
    meta: PagyInterface
  }
}

const typeOptions = [
  {
    value: 'daily',
    title: 'Daily Report'
  },
  {
    value: 'weekly',
    title: 'Weekly Report'
  },
  {
    value: 'monthly',
    title: 'Monthly Report'
  }
]

const queryMonthlyReport: GroupFilterInterface = {
  response: {
    meta: defaultPagy,
    data: []
  },
  page: {
    type: Filter.NUMBER,
    show: false,
    value: 1
  },
  per_page: {
    type: Filter.NUMBER,
    show: false,
    value: 20
  },
}

const queryS2eReport: GroupFilterInterface = {
  response: {
    meta: defaultPagy,
    data: []
  },
  page: {
    type: Filter.NUMBER,
    show: false,
    value: 1
  },
  per_page: {
    type: Filter.NUMBER,
    show: false,
    value: 20
  },
  order: {
    type: Filter.ORDER,
    show: false,
    value: [
      { field: 'streamer_name', order: null, index: -1 },
      { field: 'total_stream_time', order: null, index: -1 },
      { field: 'total_view_time', order: null, index: -1 },
      { field: 'total_views', order: null, index: -1 },
      { field: 'total_viewers', order: null, index: -1 },
      { field: 'total_s2e_earnings', order: null, index: -1 },
      { field: 'total_s2e_claimed', order: null, index: -1 }
    ]
  }
}

const queryS2eReportDetail: GroupFilterInterface = {
  response: {
    meta: defaultPagy,
    data: [],
  },
  page: {
    type: Filter.NUMBER,
    show: false,
    value: 1
  },
  per_page: {
    type: Filter.NUMBER,
    show: false,
    value: 20
  },
  category: {
    title: 'Category',
    type: Filter.SELECT_OPTIONS,
    show: true,
    clear: false,
    value: 'daily',
    options: typeOptions
  }
}

const queryUserReport: GroupFilterInterface = {
  response: {
    meta: defaultPagy,
    data: []
  },
  page: {
    type: Filter.NUMBER,
    show: false,
    value: 1
  },
  per_page: {
    type: Filter.NUMBER,
    show: false,
    value: 20
  },
  esb_user_id: {
    title: 'ESB USER ID',
    type: Filter.TEXT,
    show: true,
    value: ''
  },
  search_time: {
    title: 'DATE',
    type: Filter.RANGE_DATE_TIME,
    format: 'YYYY-MM-DD',
    show: true,
    value: null,
    more_data: {
      utc: false
    }
  },
  order: {
    type: Filter.ORDER,
    show: false,
    value: [
      { field: 'date', order: 'descend', index: -1 },
      { field: 'username', order: null, index: -1 },
      { field: 'total_view_times', order: null, index: -1 },
      { field: 'esb_user_id', order: null, index: -1 }
    ]
  }
}

const state: IReport = {
  queryS2eReport: queryS2eReport,
  queryS2eReportDetail: queryS2eReportDetail,
  queryUserReport: queryUserReport,
  streamerUrlName: '',
  streamer: '',
  queryMonthlyReport: queryMonthlyReport,
  reportDaily: {
    data: [],
    meta: {
      total: 0,
      per_page: 0,
      page: 0,
      pages: 0,
      items: 0,
      next_page: 0,
      prev_page: 0,
      from: 0,
      to: 0
    }
  }
}

const report: Module<IReport, unknown> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default report
