import { DefaultRepository } from '@/services/base'
import { UserEntity } from '@/services/entities/user'
import request from '@/plugin/request'

export default class UserRepository extends DefaultRepository<UserEntity> {
  constructor() {
    super('/bo/api/v1/users')
  }

  async getHistory(id: string | number, params = {}) {
    try {
      const result = await request.get(`${this.prefix}/${id}/w2e_histories`, { params })
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
