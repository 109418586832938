import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
    onChange: _ctx.handleChange,
    valueFormat: _ctx.format,
    showTime: _ctx.showTime,
    format: _ctx.format,
    value: _ctx.valueCustom,
    placeholder: _ctx.placeholder
  }, null, 40, ["onChange", "valueFormat", "showTime", "format", "value", "placeholder"]))
}