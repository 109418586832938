import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TableAdmin = _resolveComponent("TableAdmin")!

  return (_openBlock(), _createBlock(_component_TableAdmin, {
    module: "user",
    columns: _ctx.columns,
    store:  {
    module: 'user',
    state: 'queryUser',
    action: 'searchUser'
  }
  }, {
    bodyCell: _withCtx(({ column, record }) => [
      (column.key === 'username')
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: `/user/${record.id}`
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.username), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["columns"]))
}