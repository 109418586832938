import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b84ef704"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "table-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_TableAdmin = _resolveComponent("TableAdmin")!

  return (_openBlock(), _createBlock(_component_TableAdmin, {
    module: "streamer",
    columns: _ctx.columns,
    scroll: { x: 0 },
    store: {
      module: 'stream',
      state: 'queryPastStreamHistory',
      action: 'searchStreamerPastStreamHistory'
    }
  }, {
    bodyCell: _withCtx(({ column, record }) => [
      (column.key === 'stream_id')
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: `/stream/${record.stream_id}`
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.stream_id), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        : _createCommentVNode("", true),
      (column.key === 'social_type')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_BaseIcon, {
              name: record.social_type.toLowerCase()
            }, null, 8, ["name"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["columns"]))
}