import { ActionTree } from 'vuex'
import { ISetting } from '.'
import SettingRepository from '@/services/repositories/setting'

const actions: ActionTree<ISetting, unknown> = {
  async searchYoutubeApiKey({ commit, dispatch }) {
    try {
      dispatch('changeStateLoading', true, { root: true })
      const { dataSearch, dataOrigin, dataRoute } = await dispatch('convertDataSend', 'queryYoutubeApiKey')
      const Setting = new SettingRepository()
      const result: Record<string, any> = await Setting.getApiYoutubeKey(dataSearch)
      dataOrigin.response = result.api_keys
      commit('SET_STATE', { stateName: 'queryYoutubeApiKey', data: dataOrigin, query: dataRoute })
      dispatch('changeStateLoading', false, { root: true })
    } catch (e) {
      dispatch('changeStateLoading', false, { root: true })
      return Promise.reject(e)
    }
  }
}

export default actions
