import UserStream from '@/modules/user/components/UserStream.vue';
export default defineComponent({
  name: 'User',
  components: {
    UserStream
  },
  props: {
    userId: {
      type: [String, Number],
      require: true
    }
  },
  data() {
    return {
      activeKey: 'livestream_history',
      tabs: [{
        key: 'livestream_history',
        component: 'UserStream',
        title: this.$t('module.user.user_stream')
      }]
    };
  },
  created() {
    this.activeKey = get(this.$route, 'query.tab', 'livestream_history');
    this.$store.dispatch('user/setUserId', this.userId);
  }
});