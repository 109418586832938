import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a013a82e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "info-report-streamer"
};
const _hoisted_2 = {
  class: "title text-left"
};
const _hoisted_3 = {
  class: "group-card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Card = _resolveComponent("Card");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.get(_ctx.streamer, 'streamer_name', '')), 1), _createElementVNode("div", _hoisted_3, [_createVNode(_component_Card, {
    title: _ctx.$t('module.report.s2e_report.table.stream_duration'),
    value: _ctx.get(_ctx.streamer, 'total_stream_time', 0),
    type: "secondToTime"
  }, null, 8, ["title", "value"]), _createVNode(_component_Card, {
    title: _ctx.$t('module.report.s2e_report.table.total_view_time'),
    value: _ctx.get(_ctx.streamer, 'total_view_time', 0),
    type: "secondToTime"
  }, null, 8, ["title", "value"]), _createVNode(_component_Card, {
    title: _ctx.$t('module.report.s2e_report.table.total_earnings'),
    value: _ctx.get(_ctx.streamer, 'total_s2e_earnings', 0),
    type: "usdt",
    suffix: "$"
  }, null, 8, ["title", "value"]), _createVNode(_component_Card, {
    title: _ctx.$t('module.report.s2e_report.table.total_claimed'),
    value: _ctx.get(_ctx.streamer, 'total_s2e_claimed', 0),
    type: "usdt",
    suffix: "$"
  }, null, 8, ["title", "value"])])]);
}