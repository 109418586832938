import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toHandlers as _toHandlers, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, _mergeProps(_ctx.$attrs, _toHandlers(_ctx.$listeners), {
    value: _ctx.value,
    ref: "select",
    "show-search": "",
    onSelect: _ctx.handleChange,
    "allow-clear": "",
    "filter-option": false,
    onFocus: _ctx.handleClick,
    onSearch: _ctx.searchText
  }), {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$slots, (slot, name) => {
        return _renderSlot(_ctx.$slots, name)
      }), 256)),
      (_ctx.useLoadMore.active)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataOption, (item) => {
              return (_openBlock(), _createBlock(_component_a_select_option, {
                value: item.value,
                key: item.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.useLoadMore.option.customRender ? _ctx.useLoadMore.option.customRender(item.value, item.title, item.data) : item.title), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128)),
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_a_select_option, {
                  key: 0,
                  class: "custom-loading"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_spin)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["value", "onSelect", "onFocus", "onSearch"]))
}