import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b3ae9ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "header-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_unfold_outlined = _resolveComponent("menu-unfold-outlined")!
  const _component_menu_fold_outlined = _resolveComponent("menu-fold-outlined")!
  const _component_UserOutlined = _resolveComponent("UserOutlined")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_LogoutOutlined = _resolveComponent("LogoutOutlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", null, [
          (_ctx.value)
            ? (_openBlock(), _createBlock(_component_menu_unfold_outlined, {
                key: 0,
                class: "trigger",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeStatus(false)))
              }))
            : (_openBlock(), _createBlock(_component_menu_fold_outlined, {
                key: 1,
                class: "trigger",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeStatus(true)))
              }))
        ])
      ]),
      _createVNode(_component_a_dropdown, { placement: "bottomRight" }, {
        overlay: _withCtx(() => [
          _createVNode(_component_a_menu, {
            class: "menu",
            "selected-keys": []
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_menu_item, {
                key: "logout",
                onClick: _ctx.logout
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_LogoutOutlined),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('menu.logout')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_badge, {
            dot: "",
            class: "header-right"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_avatar, { size: 54 }, {
                icon: _withCtx(() => [
                  _createVNode(_component_UserOutlined)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}