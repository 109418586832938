import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd5335c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "first-letter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AmountEsc = _resolveComponent("AmountEsc")!
  const _component_table_admin = _resolveComponent("table-admin")!

  return (_openBlock(), _createBlock(_component_table_admin, {
    module: "poolBet",
    columns: _ctx.columns,
    ref: "template",
    store: {
      state: 'queryPoolBetHistory',
      module: 'poolBet',
      action: 'searchPoolBetHistory',
    },
    class: "bet-history"
  }, {
    bodyCell: _withCtx(({ column, record }) => [
      (column.key === 'team_name')
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(record.team_name + " (" + record.team_abbreviation + ")"), 1))
        : _createCommentVNode("", true),
      (column.key === 'bet_amount')
        ? (_openBlock(), _createBlock(_component_AmountEsc, {
            key: 1,
            amount: record.bet_amount
          }, null, 8, ["amount"]))
        : _createCommentVNode("", true),
      (column.key === 'win_amount')
        ? (_openBlock(), _createBlock(_component_AmountEsc, {
            key: 2,
            amount: record.bet_status === 'lost' ? record.bet_amount : record.win_amount,
            status: record.bet_status
          }, null, 8, ["amount", "status"]))
        : _createCommentVNode("", true),
      (column.key === 'payout')
        ? (_openBlock(), _createBlock(_component_AmountEsc, {
            key: 3,
            amount: record.payout
          }, null, 8, ["amount"]))
        : _createCommentVNode("", true),
      (column.key === 'bet_status')
        ? (_openBlock(), _createElementBlock("div", {
            key: 4,
            class: _normalizeClass(["badge", record.bet_status])
          }, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(record.bet_status), 1)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["columns"]))
}