import TableAdmin from '@/components/share/TableAdmin.vue';
export default defineComponent({
  components: {
    TableAdmin
  },
  data() {
    return {
      columns: [{
        title: this.$t('module.report.s2e_report.table.name'),
        dataIndex: 'streamer_name',
        key: 'streamer_name'
      }, {
        title: this.$t('module.report.s2e_report.table.stream_duration'),
        dataIndex: 'total_stream_time',
        key: 'total_stream_time',
        secondToTime: true
      }, {
        title: this.$t('module.report.s2e_report.table.total_view_time'),
        dataIndex: 'total_view_time',
        key: 'total_view_time',
        secondToTime: true
      }, {
        title: this.$t('module.report.s2e_report.table.total_viewers'),
        dataIndex: 'total_viewers',
        key: 'total_viewers',
        number: true
      }, {
        title: this.$t('module.report.s2e_report.table.total_s2e_earnings'),
        dataIndex: 'total_s2e_earnings',
        key: 'total_s2e_earnings',
        usdt: true
      }, {
        title: this.$t('module.report.s2e_report.table.total_s2e_claimed'),
        dataIndex: 'total_s2e_claimed',
        key: 'total_s2e_claimed',
        usdt: true
      }]
    };
  }
});