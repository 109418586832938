import TableAdmin from '@/components/share/TableAdmin.vue';
import { ROUTER_FO } from '@/utils/common';
export default defineComponent({
  components: {
    TableAdmin
  },
  data() {
    return {
      router_fo: ROUTER_FO,
      columns: [{
        title: this.$t('module.stream.table.stream_id'),
        dataIndex: 'stream_id',
        key: 'stream_id'
      }, {
        title: this.$t('module.stream.table.match_id'),
        dataIndex: 'match_id',
        key: 'match_id'
      }, {
        title: this.$t('module.stream.table.status'),
        dataIndex: 'status',
        key: 'status'
      }, {
        title: this.$t('module.stream.table.start_time'),
        dataIndex: 'start_time',
        key: 'start_time'
      }, {
        title: this.$t('module.stream.table.end_time'),
        dataIndex: 'end_time',
        key: 'end_time'
      }, {
        title: this.$t('module.stream.table.streamer'),
        dataIndex: 'streamer',
        key: 'streamer'
      }, {
        title: this.$t('module.stream.table.game_title'),
        dataIndex: 'game_name',
        key: 'game_name'
      }, {
        title: this.$t('module.stream.table.stream_title'),
        dataIndex: 'stream_title',
        key: 'stream_title',
        ellipsis: true
      }, {
        title: this.$t('module.stream.table.run_time'),
        dataIndex: 'run_time',
        key: 'run_time'
      }, {
        title: this.$t('module.stream.table.total_view'),
        dataIndex: 'total_views',
        key: 'total_views',
        number: true
      }, {
        title: this.$t('module.stream.table.peak_view'),
        dataIndex: 'peak_views',
        key: 'peak_views',
        number: true
      }, {
        title: this.$t('module.stream.table.djt_claimed'),
        dataIndex: 'djt_claimed',
        key: 'djt_claimed',
        number: true
      }]
    };
  },
  methods: {
    reload() {
      //@ts-ignore
      this.$refs.template.fetchData();
    },
    showDetail(record) {}
  }
});