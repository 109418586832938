import TableAdmin from "@/components/share/TableAdmin.vue";
import AmountEsc from "@/modules/pool-bet/components/AmountEsc.vue";
export default defineComponent({
  components: {
    TableAdmin,
    AmountEsc
  },
  data() {
    return {
      columns: [{
        title: this.$t("module.pool_bet.table.esb_user_id"),
        dataIndex: "esb_user_id",
        key: "esb_user_id"
      }, {
        title: this.$t("module.pool_bet.table.match_id"),
        dataIndex: "match_id",
        key: "match_id"
      }, {
        title: this.$t("module.pool_bet.table.league_name_en"),
        dataIndex: "league_name_en",
        key: "league_name_en"
      }, {
        title: this.$t("module.pool_bet.table.sport_type"),
        dataIndex: "sport_type",
        key: "sport_type"
      }, {
        title: this.$t("module.pool_bet.table.team_name"),
        dataIndex: "team_name",
        key: "team_name"
      }, {
        title: this.$t("module.pool_bet.table.game_order"),
        dataIndex: "game_order",
        key: "game_order"
      }, {
        title: this.$t("module.pool_bet.table.order_number"),
        dataIndex: "order_number",
        key: "order_number"
      }, {
        title: this.$t("module.pool_bet.table.bet_amount"),
        dataIndex: "bet_amount",
        key: "bet_amount",
        number: true
      }, {
        title: this.$t("module.pool_bet.table.win_amount"),
        dataIndex: "win_amount",
        key: "win_amount",
        number: true
      }, {
        title: this.$t("module.pool_bet.table.payout"),
        dataIndex: "payout",
        key: "payout",
        number: true
      }, {
        title: this.$t("module.pool_bet.table.bet_status"),
        dataIndex: "bet_status",
        key: "bet_status"
      }, {
        title: this.$t("module.pool_bet.table.created_at"),
        dataIndex: "created_at",
        key: "created_at",
        datetimeSecond: true
      }]
    };
  },
  methods: {}
});