import _ from 'lodash';
import { FilterFilled, UpOutlined } from '@ant-design/icons-vue';
import { mapState, mapActions } from 'vuex';
export default defineComponent({
  components: {
    FilterFilled,
    UpOutlined
  },
  props: {
    store: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      show: true,
      searched: true
    };
  },
  methods: {
    changeData() {
      this.searched = false;
    },
    ...mapActions({
      resetQuery(dispatch) {
        return dispatch(this.store.module + '/resetData', this.store.state);
      },
      handleSearch(dispatch) {
        dispatch(this.store.module + '/resetDataPage', this.store.state);
        dispatch(this.store.module + '/' + this.store.action);
        this.$nextTick(() => {
          this.searched = true;
        });
      }
    }),
    handleReset() {
      this.resetQuery();
      try {
        this.$nextTick(() => {
          const data = document.querySelectorAll('.ant-select-item-option-active');
          data.forEach(item => {
            item.classList.remove('ant-select-item-option-active');
          });
        });
      } catch (e) {}
      this.handleSearch();
    }
  },
  unmounted() {
    if (!this.searched) {
      this.resetQuery();
    }
  },
  computed: {
    active() {
      //@ts-ignore
      return Object.values(this.stateQuery).filter(item => {
        return item.show;
      }).length != 0;
    },
    ...mapState({
      stateQuery(state) {
        return _.get(state, this.store.module + '.' + this.store.state, {});
      }
    })
  }
});