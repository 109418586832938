import { MutationTree } from 'vuex'
import { IStream } from '.'

const mutations: MutationTree<IStream> = {
  SET_BATCH_UPDATE: (state: Record<string, any>, payload: Record<string, any>) => {
    state.batchUpdate = payload
  },
  SET_URL_NAME: (state: Record<string, any>, urlName: string) => {
    state.urlName = urlName
  },
  SET_AFFILIATE_LIST: (state: Record<string, any>, affiliateList: string[]) => {
    state.affiliateList = affiliateList
  }
}
export default mutations
