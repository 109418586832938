import _ from 'lodash'

export const ROUTER_FO = process.env.VUE_APP_BASE_FO
export const defaultPagy = _.cloneDeep({
  total: 1,
  per_page: 0,
  page: 0,
  pages: 0,
  items: 0,
  next_page: 0,
  prev_page: 0,
  from: 0,
  to: 0
})
export const categoryGame = {
  '21779': 'LOL',
  '29595': 'DOTA2',
  '32399': 'CS:GO',
  '516575': 'VALORANT',
  '494184': 'Mobile Legends: Bang Bang',
  '32982': 'GTA V',
  '509658': 'CHATTING',
  '999999': 'Other'
}
