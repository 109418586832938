import TableAdmin from '@/components/share/TableAdmin.vue';
export default defineComponent({
  name: 'UserList',
  components: {
    TableAdmin
  },
  props: {
    userId: {
      type: [String, Number],
      default: '',
      require: true
    }
  },
  data() {
    return {
      user_id: '',
      columns: [{
        title: this.$t('module.user.id'),
        dataIndex: 'id',
        key: 'id'
      }, {
        title: this.$t('module.user.esb_user_id'),
        dataIndex: 'esb_user_id',
        key: 'esb_user_id'
      }, {
        title: this.$t('module.user.username'),
        dataIndex: 'username',
        key: 'username'
      }, {
        title: this.$t('module.user.current_djt'),
        dataIndex: 'current_djt',
        key: 'current_djt',
        number: true
      }]
    };
  },
  methods: {
    handleRowClick(record, event) {
      this.$refs.modal.show(record.user_id);
    }
  }
});