import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-54bb2922"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "user-detail"
};
const _hoisted_2 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane");
  const _component_a_tabs = _resolveComponent("a-tabs");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.get(_ctx.$store.state.user, 'user.user.username')), 1), _createVNode(_component_a_tabs, {
    activeKey: _ctx.activeKey,
    "onUpdate:activeKey": _cache[0] || (_cache[0] = $event => _ctx.activeKey = $event)
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, tab => {
      return _openBlock(), _createBlock(_component_a_tab_pane, {
        key: tab.key,
        tab: tab.title
      }, {
        default: _withCtx(() => [_ctx.activeKey == tab.key ? (_openBlock(), _createBlock(_resolveDynamicComponent(tab.component), {
          key: 0,
          userId: _ctx.userId
        }, null, 8, ["userId"])) : _createCommentVNode("", true)]),
        _: 2
      }, 1032, ["tab"]);
    }), 128))]),
    _: 1
  }, 8, ["activeKey"])]);
}