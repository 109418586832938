import { DefaultRepository } from '@/services/base'
import { ViewerEntity } from '@/services/entities/viewer'
import request from '@/plugin/request'

export default class ReportRepository extends DefaultRepository<ViewerEntity> {
  constructor() {
    super(`/bo/api/v1/report`)
  }
  async getS2eReport(params: Record<string, any>): Promise<Record<string, any>> {
    try {
      const result = await request.get(`/bo/api/v1/s2e_streamers`, {params})
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getS2eReportDetail(url_name: string | number, params: Record<string, any>): Promise<Record<string, any>> {
    try {
      const result = await request.get(`/bo/api/v1/s2e_streamers/${url_name}`, { params })
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async reportDaily(payload: Record<string, any>): Promise<any> {
    try {
      const result = await request.get(`${this.prefix}/daily_reports`, {
        params: payload
      })
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async reportMonthly(params: Record<string, any>): Promise<Record<string, any>> {
    try {
      const result = await request.get(`${this.prefix}/monthly_reports`, { params })
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  
  async getUserReport(params: Record<string, any>): Promise<Record<string, any>> {
    try {
      const result = await request.get(`/bo/api/v1/users_watched_time_per_day`, {params})
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
