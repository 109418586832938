import History from '@/modules/pool-bet/components/History.vue';
export default defineComponent({
  name: 'PoolBetHistory',
  components: {
    History
  },
  data() {
    return {
      activeKey: 'bet_history',
      tabs: [{
        key: 'bet_history',
        component: 'History',
        title: this.$t('module.pool_bet.bet_history')
      }]
    };
  },
  computed: {},
  methods: {
    async onActive(tab) {
      await this.$router.replace({
        query: {
          tab: tab
        }
      });
      this.activeKey = tab;
    }
  },
  created() {
    this.activeKey = get(this.$route, 'query.tab', 'bet_history');
  }
});