<template>
  <div>
    <a-result status="404" title="404" sub-title="Sorry, the page you visited does not exist.">
      <template #extra>
        <a-button type="primary" @click="$router.push('stream')">Back stream</a-button>
      </template>
    </a-result>
  </div>
</template>
<script lang="ts"></script>
<style lang="scss" scoped>
  :deep(div) {
    color: var(--text-primary-2);
  }
</style>
