import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_select = _resolveComponent("base-select")!
  const _component_base_form_validator = _resolveComponent("base-form-validator")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    onOk: _ctx.onSubmit,
    style: {"width":"680px"},
    visible: _ctx.visible,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event)),
    confirmLoading: _ctx.loading,
    title: _ctx.$t('module.stream.batch_update', { total: (_ctx.batchUpdate.total - _ctx.batchUpdate.except_ids.length) }),
    "ok-text": _ctx.$t('common.update'),
    "cancel-text": _ctx.$t('common.cancel')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        model: _ctx.data,
        "validate-messages": _ctx.validateMessages,
        ref: "form"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_base_form_validator, {
            label: _ctx.$t('module.stream.create_streamer.livestream_reward'),
            name: "stream_reward_id",
            rules: [{ required: true }]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_base_select, {
                value: _ctx.data.stream_reward_id,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.stream_reward_id) = $event)),
                "use-load-more": {
            active: true,
            url: '/bo/api/v1/rewards',
            key: 'rewards',
            firstLoader: true,
            option: {
              customRender: (id, title, item) => {
                                return item.djt_amount_per_hour + (item.remark ? ' (' + item.remark + ')' : '')
                             },
              search: 'search_text',
              value: 'id',
              title: 'remark'
            }
          }
              }, null, 8, ["value", "use-load-more"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_base_form_validator, {
            label: _ctx.$t('module.stream.create_streamer.video_reward'),
            name: "video_reward_id",
            rules: [{ required: true }]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_base_select, {
                value: _ctx.data.video_reward_id,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.video_reward_id) = $event)),
                "use-load-more": {
            active: true,
            url: '/bo/api/v1/rewards',
            key: 'rewards',
            option: {
                 customRender: (id, title, item) => {
                             return item.djt_amount_per_hour + (item.remark ? ' (' + item.remark + ')' : '')
                            },
              search: 'search_text',
              value: 'id',
              title: 'remark'
            }
          }
              }, null, 8, ["value", "use-load-more"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_base_form_validator, {
            label: _ctx.$t('module.stream.create_streamer.status'),
            name: "status",
            rules: [{ required: true }]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.data.status,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.status) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select_option, { value: "disabled" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Disabled")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: "enabled" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Enabled")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["model", "validate-messages"])
    ]),
    _: 1
  }, 8, ["onOk", "visible", "confirmLoading", "title", "ok-text", "cancel-text"]))
}