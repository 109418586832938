import SettingRepository from '@/services/repositories/setting';
const apiSetting = new SettingRepository();
export default defineComponent({
  components: {},
  data() {
    return {
      loading: false,
      visible: false,
      validateMessages: {
        required: '${label} is required!'
      },
      formAdd: reactive({
        type: 'youtube_data_api_v3',
        key: ''
      })
    };
  },
  methods: {
    async onCancel() {
      this.$refs.form.clearValidate();
    },
    async onSubmit() {
      this.loading = true;
      this.$refs.form.validate().then(async valid => {
        await apiSetting.postApiYoutubeKey(valid);
        this.formAdd = {
          type: 'youtube_data_api_v3',
          key: ''
        };
        this.visible = false;
        this.$store.dispatch('setting/resetData', 'queryYoutubeApiKey');
        this.$store.dispatch('setting/searchYoutubeApiKey');
      }).catch(e => {});
      this.loading = false;
    },
    show() {
      this.visible = true;
    }
  }
});