import StreamerRepository from '@/services/repositories/streamer';
const apiStreamer = new StreamerRepository();
export default defineComponent({
  components: {},
  data() {
    return {
      loading: false,
      visible: false,
      validateMessages: {
        required: '${label} is required!',
        types: {
          url: '${label} is not a valid url!'
        }
      },
      channelId: '',
      formSearch: reactive({
        url: ''
      })
    };
  },
  methods: {
    async onCancel() {
      this.$refs.form.clearValidate();
    },
    async onSubmit() {
      this.loading = true;
      //@ts-ignore
      this.$refs.form.validate().then(async valid => {
        const channelId = await apiStreamer.getChannelId(valid);
        this.$emit('submit', channelId.channel_id);
        this.visible = false;
      }).catch(e => {});
      this.loading = false;
    },
    show() {
      this.visible = true;
    }
  }
});