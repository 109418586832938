import { BaseRepository } from '@/services/base'
import { LoginInterface, RefreshTokenInterface } from '@/utils/interface/common'
import request from '@/plugin/request'

export default class AuthRepository extends BaseRepository {
  constructor() {
    super('/bo/api/v1')
  }

  async login(payload: LoginInterface): Promise<Array<Record<string, any>>> {
    try {
      const result = await request.post(`${this.prefix}/sign_in`, payload)
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async refreshToken(payload: RefreshTokenInterface): Promise<Array<Record<string, any>>> {
    try {
      const result = await request.post(`${this.prefix}/refresh_token`, payload)
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
