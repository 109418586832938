import { RouteRecordRaw } from 'vue-router'
import YoutubeApiKey from '@/modules/settings/views/YoutubeApiKey.vue'

const settingRoute: RouteRecordRaw[] = [
  {
    path: '/settings/youtube-api-key',
    component: YoutubeApiKey,
    name: 'YoutubeApiKey',
    meta: {
      title: 'setting'
    }
  }
]

export default settingRoute
