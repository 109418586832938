import "core-js/modules/es.array.push.js";
import FormSearch from '@/components/share/FormSearch.vue';
import pluralize from 'pluralize';
import _ from 'lodash';
import { defaultPagy } from '@/utils/common';
export default defineComponent({
  components: {
    FormSearch
  },
  props: {
    store: {
      type: Object,
      default: () => {
        return {};
      },
      required: false
    },
    module: {
      type: String,
      default: '',
      require: false
    },
    notImmediateFetch: {
      type: Boolean,
      default: false,
      require: false
    },
    relation: {
      type: Object,
      default: () => {
        return {
          id: '',
          relation: '',
          notQuery: false
        };
      },
      require: false
    },
    type: {
      type: String,
      default: 'main',
      require: false,
      validate: value => {
        return ['main', 'relation'].includes(value);
      }
    },
    columns: {
      type: Array,
      default: () => [],
      require: true
    },
    paramsRepository: {
      type: Object,
      default: () => {
        return {};
      },
      require: false
    },
    defaultParams: {
      type: Object,
      default: () => {
        return {};
      },
      require: false
    }
  },
  computed: {
    ...mapState({
      stateQuery(state) {
        return _.get(state, this.store.module + '.' + this.store.state, {});
      }
    }),
    haveStore() {
      return !_.isEmpty(this.store);
    },
    pagy() {
      if (this.haveStore) {
        return this.$store.getters[this.store?.module + '/metaFilter'](this.store?.state);
      }
      return get(this.response, 'meta', defaultPagy);
    },
    dataSource() {
      if (this.haveStore) {
        return this.$store.getters[this.store?.module + '/dataFilter'](this.store?.state);
      }
      return get(this.response, 'data', []);
    }
  },
  data() {
    return {
      fullResponse: {},
      response: {},
      loading: false
    };
  },
  methods: {
    orderChange(dataChange) {
      if (this.haveStore) {
        const order = this.stateQuery.order?.value;
        order.forEach(itemOrder => {
          if (Array.isArray(dataChange)) {
            const indexDataChange = dataChange.findIndex(item => {
              return item.field === itemOrder.field;
            });
            const fieldOrder = dataChange[indexDataChange];
            itemOrder.index = indexDataChange;
            itemOrder.order = isEmpty(fieldOrder) ? null : fieldOrder.order;
          } else {
            itemOrder.order = itemOrder.field == dataChange.field ? dataChange.order : null;
          }
        });
        this.$store.dispatch(this.store.module + '/resetDataPage', this.store.state);
        this.$store.dispatch(this.store.module + '/' + this.store.action);
      }
    },
    updateParams() {
      const query = {
        page: undefined,
        per_page: undefined
      };
      if (this.pagy.page != 1) {
        query.page = this.pagy.page;
      }
      if (this.pagy.per_page != 20) {
        query.per_page = this.pagy.per_page;
      }
      let queryCustom = Object.assign({}, this.$route.query, query);
      this.$router.push({
        query: queryCustom
      });
    },
    async fetchData(params = {
      page: 1,
      per_page: 20
    }) {
      if (this.haveStore) {
        this.stateQuery.page.value = params.page;
        this.stateQuery.per_page.value = params.per_page;
        this.$store.dispatch(this.store.module + '/' + this.store.action);
      } else {
        try {
          this.loading = true;
          const api = getRepository(this.module, this.paramsRepository);
          params = {
            ...params,
            ...this.defaultParams
          };
          if (this.type == 'main') {
            this.fullResponse = await api.all(params);
            this.response = get(this.fullResponse, pluralize(this.module, 2), {});
          } else {
            const {
              id,
              relation
            } = this.relation;
            const relationCustom = pluralize(relation, 2);
            const relationQuery = this.relation.notQuery ? '' : relationCustom;
            this.fullResponse = await api.findModuleRelation(id, relationQuery, params);
            this.response = get(this.fullResponse, pluralize(relationCustom, 2), {});
          }
          this.updateParams();
          this.$emit('fullResponse', this.fullResponse);
          this.$emit('response', this.response);
        } catch (e) {}
        this.loading = false;
      }
    }
  },
  created() {
    if (!this.notImmediateFetch && !this.haveStore) {
      this.fetchData({
        page: this.$route.query.page || 1,
        per_page: this.$route.query.per_page || 20
      });
    }
    if (this.haveStore) {
      this.$store.dispatch(this.store.module + '/passDataFromQuery', {
        stateName: this.store?.state,
        query: this.$route.query
      });
      this.$store.dispatch(this.store.module + '/' + this.store.action);
    }
  }
});