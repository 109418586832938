import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_table = _resolveComponent("base-table")!

  return (_openBlock(), _createBlock(_component_base_table, {
    scroll: 0,
    "data-source": _ctx.data.data,
    pagy: _ctx.data.meta,
    columns: _ctx.columns,
    loading: _ctx.loading,
    onOnChangePagination: _ctx.getData
  }, {
    bodyCell: _withCtx(({ column, record }) => [
      (column.key === 'total_view_time')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.$filters.convertSecondToTime(record.total_view_time)), 1)
          ], 64))
        : _createCommentVNode("", true),
      (column.key === 'date')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(record.date.substr(0, 10)), 1)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["data-source", "pagy", "columns", "loading", "onOnChangePagination"]))
}