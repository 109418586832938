import TableAdmin from '@/components/share/TableAdmin.vue';
import InfoStreamer from '@/modules/report/components/InfoStreamer.vue';
export default defineComponent({
  name: 'S2eReportDetail',
  components: {
    TableAdmin,
    InfoStreamer
  },
  props: {
    url_name: {
      type: [String, Number],
      default: '',
      require: true
    }
  },
  data() {
    return {
      columns: [{
        title: this.$t('module.report.s2e_report.detail.table.date'),
        dataIndex: 'date',
        key: 'date',
        monthOfYear: true
      }, {
        title: this.$t('module.report.s2e_report.detail.table.total_stream_duration'),
        dataIndex: 'total_stream_duration',
        key: 'total_stream_duration',
        secondToTime: true
      }, {
        title: this.$t('module.report.s2e_report.detail.table.total_view_time'),
        dataIndex: 'total_view_time',
        key: 'total_view_time',
        secondToTime: true
      }, {
        title: this.$t('module.report.s2e_report.detail.table.total_viewers'),
        dataIndex: 'total_viewers',
        key: 'total_viewers',
        number: true
      }, {
        title: this.$t('module.report.s2e_report.detail.table.total_earnings'),
        dataIndex: 'total_earnings',
        key: 'total_earnings',
        usdt: true
      }, {
        title: this.$t('module.report.s2e_report.detail.table.ste_transaction'),
        dataIndex: 'ste_transaction',
        key: 'ste_transaction',
        datetimeSecond: true
      }]
    };
  },
  created() {
    this.$store.dispatch('report/setStreamerUrlName', this.url_name);
  }
});