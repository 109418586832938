const apiStreamer = getRepository('streamer');
import { cloneDeep } from 'lodash';
import { categoryGame } from '@/utils/common';
import GetChannelYT from '@/modules/stream/components/GetChannelYT.vue';
import ConfirmPassCode from '@/modules/stream/components/ConfirmPassCode.vue';
export default defineComponent({
  components: {
    GetChannelYT,
    ConfirmPassCode
  },
  computed: {
    affiliates() {
      return this.$store.state.stream.affiliateList;
    }
  },
  data() {
    return {
      categoryGame,
      loading: false,
      visible: false,
      validateMessages: {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
          url: '${label} is not a valid url!'
        },
        number: {
          range: '${label} must be between ${min} and ${max}'
        }
      },
      streamerOrigin: reactive({
        url_name: '',
        twitch_login: '',
        streamer_name: '',
        profile_image_url: '',
        description: '',
        status: 'enabled',
        video_reward_id: '',
        stream_reward_id: '',
        default_category: '',
        is_partner: false,
        youtube_channel_id: '',
        s2e_token_amount_per_hour: null,
        s2e_token_amount_per_hour_for_video: null,
        affiliate: '',
        affiliate_stream_reward_id: '',
        affiliate_video_reward_id: ''
      }),
      streamer: reactive({
        url_name: '',
        twitch_login: '',
        streamer_name: '',
        profile_image_url: '',
        video_reward_id: '',
        stream_reward_id: '',
        description: '',
        status: 'enabled',
        default_category: '',
        is_partner: false,
        youtube_channel_id: '',
        s2e_token_amount_per_hour: null,
        s2e_token_amount_per_hour_for_video: null,
        affiliate: '',
        affiliate_stream_reward_id: '',
        affiliate_video_reward_id: '',
        passcode: ''
      })
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      //@ts-ignore
      this.$refs.form.validate().then(async valid => {
        if (!(this.streamerOrigin.default_category in this.categoryGame) && valid.default_category == '999999') {
          valid.default_category = this.streamerOrigin.default_category;
        }
        if (this.streamerOrigin.s2e_token_amount_per_hour != this.streamer.s2e_token_amount_per_hour || this.streamerOrigin.s2e_token_amount_per_hour_for_video != this.streamer.s2e_token_amount_per_hour_for_video) {
          if (this.$refs.confirmPassCode) {
            this.$refs.confirmPassCode.show(valid);
          }
        } else {
          await apiStreamer.update(this.streamer.url_name, valid);
          this.visible = false;
          this.$emit('reloadPage');
        }
      }).catch(e => {});
      this.loading = false;
    },
    handleSubmitWithPassCode(data) {
      this.loading = true;
      apiStreamer.update(this.streamer.url_name, data).then(() => {
        this.visible = false;
        this.$emit('reloadPage');
      }).catch(e => {}).finally(() => {
        this.loading = false;
      });
    },
    show(record) {
      this.streamer = cloneDeep(record);
      this.streamerOrigin = cloneDeep(record);
      if (!this.streamerOrigin.default_category || !(this.streamerOrigin.default_category in this.categoryGame)) {
        this.streamer.default_category = '999999';
      }
      this.visible = true;
      this.$nextTick(() => {
        try {
          //@ts-ignore
          this.$refs.form.validate();
        } catch (e) {}
      });
    }
  }
});