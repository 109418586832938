import TableAdmin from '@/components/share/TableAdmin.vue';
import ShowViewer from '@/modules/stream/components/ShowViewer.vue';
export default defineComponent({
  components: {
    TableAdmin,
    ShowViewer
  },
  props: {
    stream_id: {
      type: [String, Number],
      default: '',
      require: true
    },
    type: {
      type: String,
      default: '',
      require: true
    }
  },
  data() {
    return {
      user_id: '',
      columns: [{
        title: this.$t('module.stream.viewer.table.username'),
        dataIndex: 'user',
        key: 'user'
      }, {
        title: this.$t('module.stream.start_time'),
        dataIndex: 'start_time',
        key: 'start_time'
      }, {
        title: this.$t('module.stream.end_time'),
        dataIndex: 'end_time',
        key: 'end_time'
      }, {
        title: this.$t('module.stream.viewer.table.total_view_time'),
        dataIndex: 'total_view_time_format',
        key: 'total_view_time_format'
      }, {
        title: this.$t('module.stream.viewer.table.resume_count'),
        dataIndex: 'resume_count',
        key: 'resume_count',
        number: true
      }, {
        title: this.$t('module.stream.djt_claimed_count'),
        dataIndex: 'djt_claim_count',
        key: 'djt_claim_count',
        number: true
      }, {
        title: this.$t('module.stream.total_djt_claimed'),
        dataIndex: 'total_djt_claimed',
        key: 'total_djt_claimed',
        number: true
      }, {
        title: this.$t('module.stream.missed_djt_claims'),
        dataIndex: 'missed_djt_claim_count',
        key: 'missed_djt_claim_count',
        number: true
      }]
    };
  },
  methods: {
    handleRowClick(record, event) {
      this.$refs.modal.show(record.user_id);
    }
  }
});