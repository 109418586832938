export default defineComponent({
  data() {
    return {
      visible: false
    };
  },
  methods: {
    show() {
      this.visible = true;
    }
  }
});