import { RouteRecordRaw } from 'vue-router'
import Index from '@/modules/stream/views/Index.vue'
import Detail from '@/modules/stream/views/Detail.vue'
import DetailStreamer from '@/modules/stream/views/DetailStreamer.vue'

const streamRoute: RouteRecordRaw[] = [
  {
    path: '/stream',
    component: Index,
    name: 'Stream',
    meta: {
      title: 'Stream | Streamer'
    }
  },
  {
    path: '/stream/:stream_id',
    component: Detail,
    name: 'StreamHistory',
    props: true,
    meta: {
      title: 'Stream'
    }
  },
  {
    path: '/streamer/:streamerName',
    component: DetailStreamer,
    name: 'StreamerDetail',
    meta: {
      title: 'Streamer'
    },
    props: true
  }
]

export default streamRoute
