import "core-js/modules/es.array.push.js";
import { Filter } from '@/utils/interface/common';
export function encodeSearch(typeFilter, data) {
  switch (typeFilter) {
    case Filter.RANGE_DATE_TIME:
      {
        if (data && Array.isArray(data)) return data.join('~');
        return '';
      }
    case Filter.ORDER:
      {
        return data.sort((item1, item2) => {
          if (item1.index > item2.index) {
            return 1;
          }
          return -1;
        }).reduce((accumulator, currentValue) => {
          if (currentValue.order) return `${accumulator}${accumulator ? ',' : ''}${currentValue.field}_${currentValue.order.replace('end', '')}`;
          return accumulator;
        }, '');
      }
    default:
      return data;
  }
}
export function decodeSearch(typeFilter, data, valueDefault) {
  switch (typeFilter) {
    case Filter.RANGE_DATE_TIME:
      {
        return data.split('~');
      }
    case Filter.ORDER:
      {
        const dataExplore = data.split(',');
        const filterQuery = [];
        dataExplore.forEach((item, index) => {
          const subString = item.split('_');
          const itemOrder = {
            order: subString.pop() == 'asc' ? 'ascend' : 'descend',
            field: subString.join('_'),
            index: index
          };
          filterQuery.push(itemOrder);
        });
        valueDefault.forEach(item => {
          const itemDefault = filterQuery.find(defaultData => {
            return defaultData.field == item.field;
          });
          item.order = isEmpty(itemDefault) ? null : itemDefault.order;
          item.index = isEmpty(itemDefault) ? -1 : itemDefault.index;
        });
        return valueDefault;
      }
    default:
      return data;
  }
}