import TableAdmin from '@/components/share/TableAdmin.vue';
import { createNamespacedHelpers } from 'vuex';
const {
  mapGetters
} = createNamespacedHelpers('user');
export default defineComponent({
  name: 'UserStream',
  components: {
    TableAdmin
  },
  props: {
    userId: {
      type: [String, Number],
      default: '',
      require: true
    }
  },
  computed: {
    ...mapGetters(['videos', 'streams'])
  },
  data() {
    return {
      user_id: '',
      columns: [{
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
      }, {
        title: 'TYPE',
        key: 'viewable_type'
      }, {
        title: this.$t('module.stream.streamer'),
        dataIndex: 'streamer',
        key: 'streamer'
      }, {
        title: this.$t('common.title'),
        dataIndex: 'stream',
        key: 'stream_title',
        ellipsis: true
      }, {
        title: this.$t('module.stream.game_name'),
        dataIndex: 'stream',
        key: 'game_name'
      }, {
        title: this.$t('module.stream.start_time'),
        dataIndex: 'start_time',
        key: 'start_time',
        datetime: true
      }, {
        title: this.$t('module.stream.end_time'),
        dataIndex: 'end_time',
        key: 'end_time',
        datetime: true
      }, {
        title: this.$t('module.stream.viewer.table.total_view_time'),
        dataIndex: 'total_view_time_format',
        key: 'total_view_time_format'
      }, {
        title: this.$t('module.stream.viewer.table.resume_count'),
        dataIndex: 'resume_count',
        key: 'resume_count',
        number: true
      }, {
        title: this.$t('module.stream.djt_claimed_count'),
        dataIndex: 'djt_claim_count',
        key: 'djt_claim_count',
        number: true
      }, {
        title: this.$t('module.stream.total_djt_claimed'),
        dataIndex: 'total_djt_claimed',
        key: 'total_djt_claimed',
        number: true
      }, {
        title: this.$t('module.stream.missed_djt_claims'),
        dataIndex: 'missed_djt_claim_count',
        key: 'missed_djt_claim_count',
        number: true
      }]
    };
  },
  methods: {
    detailViewable(record) {
      const detailRecord = record.viewable_type == 'Stream' ? this.streams[record.viewable_id] : this.videos[record.viewable_id];
      if (isEmpty(detailRecord)) return {};
      return detailRecord;
    }
  }
});