export default defineComponent({
  props: {
    rules: {
      type: Array,
      require: false
    },
    label: {
      type: String,
      default: '',
      require: false
    }
  },
  data() {
    return {};
  },
  methods: {}
});