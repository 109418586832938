import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5622d01c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "cursor"
}
const _hoisted_2 = { class: "status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_table_admin = _resolveComponent("table-admin")!

  return (_openBlock(), _createBlock(_component_table_admin, {
    module: "stream",
    columns: _ctx.columns,
    ref: "template",
    "default-params": { status: 'ended' },
    store: {
               state: 'queryLiveStreamHistory',
               module: 'stream',
               action: 'searchLiveStreamHistory'
  }
  }, {
    bodyCell: _withCtx(({ column, record }) => [
      (column.key === 'stream_id')
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: `/stream/${record.id}`
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.id), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        : _createCommentVNode("", true),
      (column.key === 'status')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(record.status), 1)
          ]))
        : _createCommentVNode("", true),
      (column.key === 'streamer')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createTextVNode(_toDisplayString(_ctx.$filters.get(record.streamer, 'streamer_name')) + " ", 1),
            (_ctx.$filters.get(record.streamer, 'is_partner', 'false') == 'true')
              ? (_openBlock(), _createBlock(_component_base_icon, {
                  key: 0,
                  name: "partner",
                  size: "20"
                }))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      (column.key === 'start_time' || column.key === 'end_time')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
            _createTextVNode(_toDisplayString(_ctx.$filters.formatDatetime(record[column.key])), 1)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["columns"]))
}