import "core-js/modules/es.array.push.js";
import { defineComponent, ref } from 'vue';
import { message } from 'ant-design-vue';
import BaseIcon from '@/components/base/BaseIcon.vue';
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default defineComponent({
  components: {
    BaseIcon
  },
  props: {
    images: {
      type: [String, Array],
      default: '',
      required: true
    },
    number: {
      type: Number,
      default: null,
      required: false
    }
  },
  emits: ['update:images'],
  setup(_props, {
    emit
  }) {
    const url = ref(process.env.VUE_APP_BASE_API + '/bo/api/v1/upload');
    const previewVisible = ref(false);
    const previewImage = ref('');
    const previewTitle = ref('');
    const fileList = ref([]);
    //@ts-ignore
    function handleChange({
      file
    }) {
      if (file.status === 'done') {
        emit('update:images', file.response.url);
      }
      if (file.status === 'error') {
        emit('update:images', null);
        message.error('Upload image error!');
      }
    }
    const hiddenImage = computed(() => {
      //@ts-ignore
      const statusError = !!fileList.value.filter(item => {
        return item.status === 'error';
      });
      return statusError;
    });
    //@ts-ignore
    const beforeUpload = file => {
      const isJpgOrPng = file.type.startsWith('image');
      if (!isJpgOrPng) {
        message.error('You can only upload image');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      if (isJpgOrPng && isLt2M) {
        return true;
      }
      emit('update:images', null);
      return false;
    };
    function removeFie() {
      emit('update:images', null);
    }
    function getName(name) {
      const dataName = name.split('/');
      return dataName.pop() || '';
    }
    function loadChange() {
      fileList.value = [];
      if (_props.images) {
        fileList.value.push({
          uid: Math.random() + '',
          name: getName(_props.images || ''),
          status: 'done',
          url: _props.images || ''
        });
      }
    }
    watch(_props, () => {
      loadChange();
    }, {
      deep: true
    });
    onMounted(() => {
      loadChange();
    });
    const handleCancel = () => {
      previewVisible.value = false;
      previewTitle.value = '';
    };
    //@ts-ignore
    const handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
      previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
    };
    return {
      hiddenImage,
      removeFie,
      handleChange,
      previewVisible,
      previewImage,
      fileList,
      handleCancel,
      handlePreview,
      previewTitle,
      beforeUpload,
      url
    };
  }
});