import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["amount-esc", _ctx.amount > 0 ? _ctx.status : ''])
  }, [
    _createVNode(_component_BaseIcon, { name: "esc" }),
    _createTextVNode(" " + _toDisplayString((_ctx.status === "lost" ? "-" : "") + _ctx.$filters.formatNumberDot(_ctx.amount >= 0 ? _ctx.amount : 0)), 1)
  ], 2))
}