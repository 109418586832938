import { RouteRecordRaw } from 'vue-router'
import Index from '@/modules/pool-bet/views/Index.vue'

const poolBetRoute: RouteRecordRaw[] = [
  {
    path: '/pool-bet',
    component: Index,
    name: 'PoolBet',
    meta: {
      title: 'PoolBet'
    }
  }
]

export default poolBetRoute
