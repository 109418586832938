import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1196aae0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "group-card" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "d-flex align-start" }
const _hoisted_4 = { class: "sub-group" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = { class: "content" }
const _hoisted_7 = { class: "d-flex align-start justify-start" }
const _hoisted_8 = { class: "sub-group mr-24" }
const _hoisted_9 = { class: "label" }
const _hoisted_10 = { class: "content" }
const _hoisted_11 = { class: "sub-group" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "content" }
const _hoisted_14 = { class: "sub-group" }
const _hoisted_15 = { class: "label" }
const _hoisted_16 = { class: "content stream-title" }
const _hoisted_17 = { class: "card" }
const _hoisted_18 = { class: "sub-group" }
const _hoisted_19 = { class: "label" }
const _hoisted_20 = { class: "content" }
const _hoisted_21 = { class: "sub-group" }
const _hoisted_22 = { class: "label" }
const _hoisted_23 = { class: "content" }
const _hoisted_24 = { class: "sub-group" }
const _hoisted_25 = { class: "label" }
const _hoisted_26 = { class: "content stream-title" }
const _hoisted_27 = { class: "card" }
const _hoisted_28 = { class: "sub-group" }
const _hoisted_29 = { class: "label" }
const _hoisted_30 = { class: "content" }
const _hoisted_31 = { class: "sub-group" }
const _hoisted_32 = { class: "label" }
const _hoisted_33 = { class: "content" }
const _hoisted_34 = {
  key: 0,
  class: "sub-group"
}
const _hoisted_35 = { class: "label" }
const _hoisted_36 = { class: "content" }
const _hoisted_37 = { class: "card" }
const _hoisted_38 = { class: "d-flex" }
const _hoisted_39 = { class: "sub-group" }
const _hoisted_40 = { class: "label" }
const _hoisted_41 = { class: "content" }
const _hoisted_42 = { class: "sub-group" }
const _hoisted_43 = { class: "label" }
const _hoisted_44 = { class: "content" }
const _hoisted_45 = { class: "sub-group" }
const _hoisted_46 = { class: "label" }
const _hoisted_47 = { class: "content" }
const _hoisted_48 = { class: "sub-group" }
const _hoisted_49 = { class: "label" }
const _hoisted_50 = { class: "content" }
const _hoisted_51 = { class: "card" }
const _hoisted_52 = { class: "sub-group" }
const _hoisted_53 = { class: "label" }
const _hoisted_54 = { class: "content" }
const _hoisted_55 = { class: "sub-group" }
const _hoisted_56 = { class: "label" }
const _hoisted_57 = { class: "content" }
const _hoisted_58 = { class: "sub-group" }
const _hoisted_59 = { class: "label" }
const _hoisted_60 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_detail_stream = _resolveComponent("detail-stream")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('module.stream.stream_id')), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.stream.id), 1)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["status", _ctx.stream.status])
          }, _toDisplayString(_ctx.stream.status), 3)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('module.stream.game_title')), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.stream.game_name), 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('module.stream.streamer')), 1),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$filters.get(_ctx.stream, 'streamer.streamer_name')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('module.stream.stream_title')), 1),
          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.stream.stream_title), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('module.stream.start_time')), 1),
          _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$filters.formatDatetime(_ctx.stream.start_time)), 1)
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('module.stream.end_time')), 1),
          _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$filters.formatDatetime(_ctx.stream.end_time) || '-'), 1)
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t('module.stream.running_time')), 1),
          _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.stream.run_time), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t('module.stream.total_viewers')), 1),
          _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$filters.formatNumberDot(_ctx.stream.total_views)), 1)
        ]),
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$t('module.stream.peak_viewers')), 1),
          _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$filters.formatNumberDot(_ctx.stream.peak_views)), 1)
        ]),
        (_ctx.stream.status == 'live')
          ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
              _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.$t('module.stream.current_viewers')), 1),
              _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.$filters.formatNumberDot(_ctx.stream.current_views >= 0 ? _ctx.stream.current_views : 0)), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_37, [
        _createElementVNode("div", _hoisted_38, [
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.$t('module.stream.total_djt_claimed')), 1),
            _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.$filters.formatNumberDot(_ctx.stream.djt_claimed)), 1)
          ]),
          _createElementVNode("div", _hoisted_42, [
            _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.$t('module.stream.create_streamer.djt_amount_per_hour')), 1),
            _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.$filters.formatNumberDot(_ctx.stream.djt_amount_per_hour)), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_45, [
          _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.$t('module.stream.djt_claimed_count')), 1),
          _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.$filters.formatNumberDot(_ctx.stream.djt_claim_count)), 1)
        ]),
        _createElementVNode("div", _hoisted_48, [
          _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.$t('module.stream.missed_djt_claims')), 1),
          _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.$filters.formatNumberDot(_ctx.stream.missed_djt_claim_count)), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_51, [
        _createElementVNode("div", _hoisted_52, [
          _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.$t('module.stream.create_streamer.five_minutes_promotion_id')), 1),
          _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.stream.five_minutes_promotion_id), 1)
        ]),
        _createElementVNode("div", _hoisted_55, [
          _createElementVNode("div", _hoisted_56, _toDisplayString(_ctx.$t('module.stream.create_streamer.ten_minutes_promotion_id')), 1),
          _createElementVNode("div", _hoisted_57, _toDisplayString(_ctx.stream.ten_minutes_promotion_id), 1)
        ]),
        _createElementVNode("div", _hoisted_58, [
          _createElementVNode("div", _hoisted_59, _toDisplayString(_ctx.$t('module.stream.create_streamer.fifteen_minutes_promotion_id')), 1),
          _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.stream.fifteen_minutes_promotion_id), 1)
        ])
      ])
    ]),
    _createVNode(_component_a_tabs, {
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _ctx.onActive,
      class: "detail-stream"
    }, {
      default: _withCtx(() => [
        (_ctx.stream.status == 'live')
          ? (_openBlock(), _createBlock(_component_a_tab_pane, {
              key: "current",
              tab: _ctx.$t('module.stream.viewer.current')
            }, {
              default: _withCtx(() => [
                ('current' == _ctx.activeKey)
                  ? (_openBlock(), _createBlock(_component_detail_stream, {
                      key: 0,
                      onStream: _ctx.updateData,
                      type: "current",
                      stream_id: _ctx.stream_id
                    }, null, 8, ["onStream", "stream_id"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["tab"]))
          : _createCommentVNode("", true),
        (_ctx.stream.status == 'live')
          ? (_openBlock(), _createBlock(_component_a_tab_pane, {
              key: "total",
              tab: _ctx.$t('module.stream.viewer.total')
            }, {
              default: _withCtx(() => [
                ('total' == _ctx.activeKey)
                  ? (_openBlock(), _createBlock(_component_detail_stream, {
                      key: 0,
                      onStream: _ctx.updateData,
                      type: "total",
                      stream_id: _ctx.stream_id
                    }, null, 8, ["onStream", "stream_id"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["tab"]))
          : _createCommentVNode("", true),
        (_ctx.stream.status == 'ended')
          ? (_openBlock(), _createBlock(_component_a_tab_pane, {
              key: "viewer",
              tab: _ctx.$t('module.stream.viewer.viewer')
            }, {
              default: _withCtx(() => [
                ('viewer' == _ctx.activeKey)
                  ? (_openBlock(), _createBlock(_component_detail_stream, {
                      key: 0,
                      ref: "viewer",
                      onStream: _ctx.updateData,
                      type: "viewer",
                      stream_id: _ctx.stream_id
                    }, null, 8, ["onStream", "stream_id"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["tab"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["activeKey", "onUpdate:activeKey"])
  ]))
}