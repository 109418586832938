import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { Filter, GroupFilterInterface } from '@/utils/interface/common'
import { defaultPagy } from '@/utils/common'

export interface ISetting {
  queryYoutubeApiKey: GroupFilterInterface
}

const state: ISetting = {
  queryYoutubeApiKey: {
    response: {
      meta: defaultPagy,
      data: []
    },
    page: {
      type: Filter.NUMBER,
      show: false,
      value: 1
    },
    per_page: {
      type: Filter.NUMBER,
      show: false,
      value: 20
    }
  }
}

const setting: Module<ISetting, unknown> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default setting
