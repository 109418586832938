import { ActionTree } from 'vuex'
import { PoolBet } from '.'
import PoolBetRepository from '@/services/repositories/pool-bet'

const actions: ActionTree<PoolBet, unknown> = {
  async searchPoolBetHistory({ commit, dispatch }) {
    try {
      dispatch('changeStateLoading', true, { root: true })
      const { dataSearch, dataOrigin, dataRoute } = await dispatch('convertDataSend', 'queryPoolBetHistory')
      const PoolBet = new PoolBetRepository()
      const result: Record<string, any> = await PoolBet.all(dataSearch)
      dataOrigin.response = result.data
      commit('SET_STATE', { stateName: 'queryPoolBetHistory', data: dataOrigin, query: dataRoute })
      dispatch('changeStateLoading', false, { root: true })
    } catch (e) {
      dispatch('changeStateLoading', false, { root: true })
      return Promise.reject(e)
    }
  },

}

export default actions
