import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05cd8cde"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 3,
  class: "uppercase"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TableAdmin = _resolveComponent("TableAdmin")!

  return (_openBlock(), _createBlock(_component_TableAdmin, {
    module: "user",
    ref: "table",
    columns: _ctx.columns,
    store: {module: 'user', state: 'queryHistoryLivestreamUser', action: 'searchLiveStreamHistory'}
  }, {
    bodyCell: _withCtx(({ column, record }) => [
      (column.key === 'id')
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: record.viewable_type == 'Stream' ? `/stream/${record.viewable_id}` : '#'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.viewable_id), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        : _createCommentVNode("", true),
      (column.key === 'streamer')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(record.streamer.streamer_name), 1)
          ], 64))
        : _createCommentVNode("", true),
      (column.key === 'stream_title')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createTextVNode(_toDisplayString(_ctx.detailViewable(record).title), 1)
          ], 64))
        : _createCommentVNode("", true),
      (column.key === 'viewable_type')
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(record.viewable_type), 1))
        : _createCommentVNode("", true),
      (column.key === 'game_name')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
            _createTextVNode(_toDisplayString(_ctx.detailViewable(record).game), 1)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["columns"]))
}