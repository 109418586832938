import { MenuUnfoldOutlined, MenuFoldOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons-vue';
const {
  mapActions
} = createNamespacedHelpers('auth');
export default defineComponent({
  components: {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    LogoutOutlined,
    UserOutlined
  },
  props: {
    value: {
      type: Boolean,
      require: true
    }
  },
  data() {
    return {
      language: [
      /*{
      icon: '🇨🇳',
      key: 'zh-CN',
      title: '简体中文'
      },*/
      {
        icon: '🇺🇸',
        key: 'en-US',
        title: 'English'
      }]
    };
  },
  methods: {
    ...mapActions(['logout']),
    changeStatus(status) {
      this.$emit('update:value', status);
    }
  }
});