import TableAdmin from '@/components/share/TableAdmin.vue';
import BaseIcon from '@/components/base/BaseIcon.vue';
import _ from 'lodash';
export default defineComponent({
  components: {
    TableAdmin,
    BaseIcon
  },
  props: {
    streamerName: {
      type: [String],
      default: '',
      require: true
    }
  },
  computed: {
    streamer() {
      return get(this.response, 'streamer', {});
    }
  },
  methods: {
    getPlatforms(list) {
      return _.orderBy(_.uniqBy(list, 'platform_type'), 'platform_type', 'asc');
    }
  },
  data() {
    return {
      response: {},
      columns: [{
        title: this.$t('module.stream.stream_id'),
        dataIndex: 'id',
        key: 'id'
      }, {
        title: this.$t('module.stream.table.platform'),
        dataIndex: 'platform',
        key: 'platform'
      }, {
        title: this.$t('module.stream.table.status'),
        dataIndex: 'status',
        key: 'status'
      }, {
        title: this.$t('module.stream.start_time'),
        dataIndex: 'start_time',
        key: 'start_time',
        datetime: true
      }, {
        title: this.$t('module.stream.end_time'),
        dataIndex: 'end_time',
        key: 'end_time',
        datetime: true
      }, {
        title: this.$t('module.stream.streamer'),
        dataIndex: 'streamer',
        key: 'streamer'
      }, {
        title: this.$t('module.stream.game_title'),
        dataIndex: 'game_name',
        key: 'game_name',
        ellipsis: true
      }, {
        title: this.$t('module.stream.stream_title'),
        dataIndex: 'stream_title',
        key: 'stream_title',
        ellipsis: true
      }, {
        title: this.$t('module.stream.table.total_view'),
        dataIndex: 'total_views',
        key: 'total_views',
        number: true
      }, {
        title: this.$t('module.stream.table.peak_view'),
        dataIndex: 'peak_views',
        key: 'peak_views',
        number: true
      }]
    };
  }
});