import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { GroupFilterInterface, Filter } from '@/utils/interface/common'
import { defaultPagy } from '@/utils/common'

export interface IStream {
  queryLiveStream: GroupFilterInterface
  queryLiveStreamHistory: GroupFilterInterface
  queryReward: GroupFilterInterface
  queryStreamHistory: GroupFilterInterface
  queryPastStreamHistory: GroupFilterInterface
  batchUpdate: {
    total: number
    ids: number[]
    type: 'id' | 'one_page' | 'all_page'
    except_ids: number[]
  }
  urlName: '',
  affiliateList: string[]
}

const queryReward: GroupFilterInterface = {
  response: {
    meta: defaultPagy,
    data: []
  },
  page: {
    type: Filter.NUMBER,
    show: false,
    value: 1
  },
  per_page: {
    type: Filter.NUMBER,
    show: false,
    value: 20
  },
  tab: {
    type: Filter.TEXT,
    defaultParam: true,
    show: false,
    value: 'reward'
  }
}
const queryStreamHistory: GroupFilterInterface = {
  response: {
    meta: defaultPagy,
    data: []
  },
  page: {
    type: Filter.NUMBER,
    show: false,
    value: 1
  },
  per_page: {
    type: Filter.NUMBER,
    show: false,
    value: 20
  },
  tab: {
    type: Filter.TEXT,
    defaultParam: true,
    show: false,
    value: 'streamer_stream_history'
  }
}
const queryPastStreamHistory: GroupFilterInterface = {
  response: {
    meta: defaultPagy,
    data: []
  },
  page: {
    type: Filter.NUMBER,
    show: false,
    value: 1
  },
  per_page: {
    type: Filter.NUMBER,
    show: false,
    value: 20
  },
  tab: {
    type: Filter.TEXT,
    defaultParam: true,
    show: false,
    value: 'past_stream_history'
  }
}
const queryDefaultStream: GroupFilterInterface = {
  response: {
    meta: defaultPagy,
    data: []
  },
  page: {
    type: Filter.NUMBER,
    show: false,
    value: 1
  },
  per_page: {
    type: Filter.NUMBER,
    show: false,
    value: 20
  },
  twitch_login: {
    title: 'STREAMER',
    type: Filter.SELECT_LOAD_MORE,
    show: true,
    value: null,
    more_data: {
      useLoadMore: {
        active: true,
        firstLoader: true,
        url: '/bo/api/v1/streamers',
        key: 'streamers',
        option: {
          customRender: (id: any, title: any) => {
            return `${title} (${id})`
          },
          value: 'twitch_login',
          title: 'streamer_name'
        }
      }
    }
  },
  start_time: {
    type: Filter.RANGE_DATE_TIME,
    show: true,
    value: null,
    title: 'START TIME',
    more_data: {
      utc: true
    }
  },
  end_time: {
    title: 'END TIME',
    type: Filter.RANGE_DATE_TIME,
    show: true,
    value: null,
    more_data: {
      utc: true
    }
  },
  game_title: {
    title: 'GAME TITLE',
    type: Filter.TEXT,
    show: true,
    value: ''
  },
  order: {
    type: Filter.ORDER,
    show: false,
    value: [
      { field: 'start_time', order: 'descend', index: -1 },
      { field: 'run_time', order: null, index: -1 },
      { field: 'current_views', order: null, index: -1 },
      { field: 'total_views', order: null, index: -1 },
      { field: 'peak_views', order: null, index: -1 },
      { field: 'djt_claimed', order: null, index: -1 }
    ]
  }
}
const queryDefaultStreamHistory: GroupFilterInterface = {
  response: {
    meta: defaultPagy,
    data: []
  },
  page: {
    type: Filter.NUMBER,
    show: false,
    value: 1
  },
  status: {
    type: Filter.TEXT,
    show: false,
    value: 'ended'
  },
  tab: {
    type: Filter.TEXT,
    defaultParam: true,
    show: false,
    value: 'stream_history'
  },
  per_page: {
    type: Filter.NUMBER,
    show: false,
    value: 20
  },
  twitch_login: {
    title: 'STREAMER',
    type: Filter.SELECT_LOAD_MORE,
    show: true,
    value: null,
    more_data: {
      useLoadMore: {
        customRender: (id: any, title: any) => {
          return `${title} (${id})`
        },
        active: true,
        firstLoader: true,
        url: '/bo/api/v1/streamers',
        key: 'streamers',
        option: {
          value: 'twitch_login',
          title: 'streamer_name'
        }
      }
    }
  },
  start_time: {
    type: Filter.RANGE_DATE_TIME,
    show: true,
    value: null,
    title: 'START TIME',
    more_data: {
      utc: true
    }
  },
  end_time: {
    title: 'END TIME',
    type: Filter.RANGE_DATE_TIME,
    show: true,
    value: null,
    more_data: {
      utc: true
    }
  },
  game_title: {
    title: 'GAME TITLE',
    type: Filter.TEXT,
    show: true,
    value: ''
  },
  order: {
    type: Filter.ORDER,
    show: false,
    value: [
      { field: 'start_time', order: 'descend', index: -1 },
      { field: 'run_time', order: null, index: -1 },
      { field: 'current_views', order: null, index: -1 },
      { field: 'total_views', order: null, index: -1 },
      { field: 'peak_views', order: null, index: -1 },
      { field: 'djt_claimed', order: null, index: -1 }
    ]
  }
}
const state: IStream = {
  queryLiveStream: queryDefaultStream,
  queryLiveStreamHistory: queryDefaultStreamHistory,
  queryStreamHistory: queryStreamHistory,
  queryPastStreamHistory: queryPastStreamHistory,
  queryReward: queryReward,
  urlName: '',
  batchUpdate: {
    total: 0,
    ids: [],
    except_ids: [],
    type: 'id'
  },
  affiliateList: []
}

const home: Module<IStream, unknown> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default home
