<template>
  <a-spin :spinning="requests">
    <slot></slot>
  </a-spin>
</template>

<script>
  export default {
    computed: {
      requests() {
        return false
      }
    }
  }
</script>
