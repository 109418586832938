import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableAdmin = _resolveComponent("TableAdmin")!

  return (_openBlock(), _createBlock(_component_TableAdmin, {
    module: "reward",
    columns: _ctx.columns,
    scroll: {x: 0},
    store: {
    module: 'stream',
    state: 'queryReward',
    action: 'searchReward',
  }
  }, null, 8, ["columns"]))
}