import { MutationTree } from 'vuex'
import { IUser } from '.'

const mutations: MutationTree<IUser> = {
  SET_USER_ID: (state, data) => {
    state.userId = data
  },
  SET_USER: (state, data) => {
    state.user = data
  }
}
export default mutations
