import HistoryStream from '@/modules/stream/components/HistoryStream.vue';
import Streamer from '@/modules/stream/components/StreamerList.vue';
import LiveStream from '@/modules/stream/components/LiveStream.vue';
import AddStreamer from '@/modules/stream/components/AddStreamer.vue';
import AddReward from '@/modules/stream/components/AddReward.vue';
import BatchUpdateStreamer from '@/modules/stream/components/BatchUpdateStreamer.vue';
import Reward from '@/modules/stream/components/Reward.vue';
import GetChannelYT from '@/modules/stream/components/GetChannelYT.vue';
import { createNamespacedHelpers } from 'vuex';
const streamMapper = createNamespacedHelpers('stream');
export default defineComponent({
  name: 'StreamView',
  components: {
    HistoryStream,
    Streamer,
    LiveStream,
    AddStreamer,
    AddReward,
    BatchUpdateStreamer,
    Reward,
    GetChannelYT
  },
  data() {
    return {
      activeKey: '',
      tabs: [{
        key: 'live_stream',
        component: 'LiveStream',
        title: this.$t('module.stream.live_stream')
      }, {
        key: 'stream_history',
        component: 'HistoryStream',
        title: this.$t('module.stream.stream_history')
      }, {
        key: 'streamer',
        component: 'Streamer',
        title: this.$t('module.stream.streamer')
      }, {
        key: 'reward',
        component: 'Reward',
        title: this.$t('module.stream.reward')
      }]
    };
  },
  computed: {
    ...streamMapper.mapState(['batchUpdate'])
  },
  methods: {
    ...streamMapper.mapActions(['setBatchUpdate']),
    async onActive(tab) {
      await this.$router.replace({
        query: {
          tab: tab
        }
      });
      this.activeKey = tab;
    },
    reloadPageStreamer() {
      this.setBatchUpdate({
        ids: [],
        type: 'id',
        total: 0,
        except_ids: []
      });
      try {
        //@ts-ignore
        this.$refs.streamer[0].reload();
      } catch (e) {}
    }
  },
  unmounted() {
    this.$store.dispatch('stream/resetData', 'queryLiveStream');
    this.$store.dispatch('stream/resetData', 'queryLiveStreamHistory');
  },
  created() {
    this.activeKey = get(this.$route, 'query.tab', 'live_stream');
  }
});