import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { Filter, GroupFilterInterface } from '@/utils/interface/common'
import { defaultPagy } from '@/utils/common'

export interface IUser {
  user: Record<string, any>
  userId: string | number
  queryHistoryLivestreamUser: GroupFilterInterface
  queryHistoryPartVideoUser: GroupFilterInterface
  queryUser: GroupFilterInterface
}

const state: IUser = {
  user: {},
  userId: '',
  queryHistoryLivestreamUser: {
    response: {
      meta: defaultPagy,
      data: []
    },
    page: {
      type: Filter.NUMBER,
      show: false,
      value: 1
    },
    per_page: {
      type: Filter.NUMBER,
      show: false,
      value: 20
    },
    tab: {
      type: Filter.TEXT,
      defaultParam: true,
      show: false,
      value: 'livestream_history'
    }
  },
  queryHistoryPartVideoUser: {
    response: {
      meta: defaultPagy,
      data: []
    },
    page: {
      type: Filter.NUMBER,
      show: false,
      value: 1
    },
    per_page: {
      type: Filter.NUMBER,
      show: false,
      value: 20
    },
    tab: {
      type: Filter.TEXT,
      defaultParam: true,
      show: false,
      value: 'part_video_history'
    }
  },
  queryUser: {
    search_text: {
      title: 'USERNAME',
      type: Filter.TEXT,
      show: true,
      value: ''
    },
    response: {
      meta: defaultPagy,
      data: []
    },
    page: {
      type: Filter.NUMBER,
      show: false,
      value: 1
    },
    per_page: {
      type: Filter.NUMBER,
      show: false,
      value: 20
    },
    tab: {
      type: Filter.TEXT,
      defaultParam: true,
      show: false,
      value: 'user_list'
    },
    order: {
      type: Filter.ORDER,
      show: false,
      value: [
        { field: 'current_djt', order: null, index: -1 },
      ]
    }
  }
}

const user: Module<IUser, unknown> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default user
