import DetailStream from '@/modules/stream/components/DetailStream.vue';
import _ from 'lodash';
export default defineComponent({
  name: 'Detail',
  components: {
    DetailStream
  },
  methods: {
    async onActive(tab) {
      await this.$router.replace({
        query: {
          tab: tab
        }
      });
      this.activeKey = tab;
    },
    updateData(data) {
      if (data.status != get(this.stream, 'status')) {
        if (data.status == 'live') {
          this.activeKey = 'current';
        } else {
          this.activeKey = 'viewer';
        }
      }
      this.stream = data;
    }
  },
  props: {
    stream_id: {
      type: [String, Number],
      default: '',
      require: true
    }
  },
  data() {
    return {
      activeKey: '',
      stream: {}
    };
  },
  async mounted() {
    try {
      const api = getRepository('viewer', {
        stream_id: this.stream_id
      });
      const data = await api.all({
        page: this.$route.query.page,
        per_page: this.$route.query.per_page
      });
      this.updateData(_.get(data, 'stream', {}));
      this.$nextTick(() => {
        _.set(this.$refs, 'viewer.$refs.table.response', _.get(data, 'viewers', {}));
      });
    } catch (e) {}
  }
});