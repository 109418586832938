import { RouteRecordRaw } from 'vue-router'
import Login from '@/modules/auth/views/Login.vue'
import store from '@/store'

const loginRoute: RouteRecordRaw[] = [
  {
    path: '/login',
    component: Login,
    meta: {
      layout: 'auth',
      isNotLogin: true
    },
    beforeEnter(from, to, next) {
      //@ts-ignore
      if (store.state.auth.access_token) {
        next('/')
        return
      } else {
        next()
      }
    },
    name: 'Login'
  }
]

export default loginRoute
