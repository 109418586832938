import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_base_form_validator = _resolveComponent("base-form-validator")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    onOk: _ctx.onSubmit,
    onCancel: _ctx.onCancel,
    style: {"width":"680px"},
    visible: _ctx.visible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
    title: _ctx.$t('module.stream.please_enter_passcode'),
    "ok-text": _ctx.$t('common.submit'),
    "cancel-text": _ctx.$t('common.cancel')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        model: _ctx.formConfirm,
        "validate-messages": _ctx.validateMessages,
        ref: "formConfirm"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_base_form_validator, {
            label: "Passcode",
            name: "passcode",
            rules: [{ required: true }]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                ref: "inputCode",
                type: "password",
                autocomplete: "off",
                value: _ctx.formConfirm.passcode,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formConfirm.passcode) = $event)),
                placeholder: "Enter passcode"
              }, null, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "validate-messages"])
    ]),
    _: 1
  }, 8, ["onOk", "onCancel", "visible", "title", "ok-text", "cancel-text"]))
}