import { RouteRecordRaw } from 'vue-router'
import Detail from '@/modules/user/views/Detail.vue'
import Index from '@/modules/user/views/Index.vue'

const userRoute: RouteRecordRaw[] = [
  {
    path: '/user',
    component: Index,
    name: 'User',
    meta: {
      title: 'User'
    }
  },
  {
    path: '/user/:userId',
    component: Detail,
    name: 'DetailUser',
    meta: {
      title: 'User'
    },
    props: true
  }
]

export default userRoute
