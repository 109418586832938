import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TableAdmin = _resolveComponent("TableAdmin")!
  const _component_ShowViewer = _resolveComponent("ShowViewer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TableAdmin, {
      "not-immediate-fetch": !['current', 'total'].includes(_ctx.type),
      onRowClick: _ctx.handleRowClick,
      module: "viewer",
      ref: ['current', 'total'].includes(_ctx.type) ? 'current' : 'table',
      "default-params": _ctx.type == 'current' ? { viewer_status: 'viewing' } : {},
      columns: _ctx.columns,
      "params-repository": { stream_id: _ctx.stream_id },
      onFullResponse: _cache[0] || (_cache[0] = data => _ctx.$emit('stream', data.stream))
    }, {
      bodyCell: _withCtx(({ column, record }) => [
        (column.key === 'user')
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: `/user/${record.user_id}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(record.user.username), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          : _createCommentVNode("", true),
        (column.key === 'start_time' || column.key === 'end_time')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.$filters.formatDatetime(record[column.key])), 1)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["not-immediate-fetch", "onRowClick", "default-params", "columns", "params-repository"]),
    _createVNode(_component_ShowViewer, {
      ref: "modal",
      stream_id: _ctx.stream_id
    }, null, 8, ["stream_id"])
  ], 64))
}