import _ from 'lodash';
export default defineComponent({
  components: {},
  props: {
    scroll: {
      type: [Number, String],
      default: 2000
    },
    pagy: {
      type: Object,
      require: false,
      default: () => {
        return {};
      }
    },
    order: {
      type: Object,
      require: false,
      default: () => {
        return {};
      }
    },
    rowSelection: {
      type: Object,
      require: false
    },
    loading: {
      type: Boolean,
      require: false,
      default: false
    },
    columns: {
      type: Array,
      require: false,
      default: () => []
    },
    dataSource: {
      type: Array,
      require: false,
      default: () => []
    }
  },
  computed: {
    rowSelectionCustom() {
      if (this.rowSelection?.typeSelect === 'all_page') {
        const result = _.cloneDeep(this.rowSelection);
        result.selectedRowKeys = this.dataSource?.map(item => {
          return item.id;
        });
        result.selectedRowKeys = result.selectedRowKeys.filter(item => {
          return !this.rowSelection?.exceptIds.includes(item);
        });
        return result;
      } else {
        return this.rowSelection;
      }
    },
    columnsCustom() {
      return this.columns?.map(item => {
        if (!item.key) {
          item.key = item.dataIndex;
        }
        if (item.number) {
          item.customRender = record => {
            //@ts-ignore
            return this.$filters.formatNumberDot(record.text >= 0 ? record.text : 0);
          };
        }
        if (item.usdt) {
          item.customRender = record => {
            //@ts-ignore
            return this.$filters.formatUSDT(record.text >= 0 ? record.text : 0);
          };
        }
        if (item.monthOfYear) {
          item.customRender = record => {
            //@ts-ignore
            return this.$filters.formatMonthOfYear(record.text);
          };
        }
        if (item.date) {
          item.customRender = record => {
            //@ts-ignore
            return this.$filters.formatDate(record.text, item.format);
          };
        }
        if (item.datetime) {
          item.customRender = record => {
            //@ts-ignore
            return this.$filters.formatDatetime(record.text);
          };
        }
        if (item.datetimeSecond) {
          item.customRender = record => {
            //@ts-ignore
            return this.$filters.formatDatetimeSecond(record.text);
          };
        }
        if (item.secondToTime) {
          item.customRender = record => {
            //@ts-ignore
            return this.$filters.convertSecondToTime(record.text) || '-';
          };
        }
        if (this.order.length > 0) {
          const itemFind = this.order.find(itemOrder => {
            return itemOrder.field === item.dataIndex;
          });
          if (itemFind) {
            item.sorter = {
              multiple: 10
            };
            item.sortOrder = itemFind.order;
          }
        }
        return item;
      });
    }
  },
  watch: {
    pagy: {
      deep: true,
      handler() {
        this.pagyConfig.per_page = get(this.pagy, 'per_page', 10);
        this.pagyConfig.page = get(this.pagy, 'page', 1);
      }
    },
    pagyConfig: {
      deep: true,
      handler() {
        if (this.pagy.per_page != this.pagyConfig.per_page || this.pagy.page != this.pagyConfig.page) {
          this.$emit('onChangePagination', this.pagyConfig);
        }
      }
    }
  },
  methods: {
    handleChange(paginationChange, columnChange, orderChange) {
      this.$emit('orderChange', orderChange);
    },
    changeValue(value) {
      this.pagyConfig.page = 1;
      this.pagyConfig.per_page = value;
    },
    nextPage() {
      //@ts-ignore
      if (this.pagy.page != this.pagy.pages) {
        this.pagyConfig.page = this.pagyConfig.page + 1;
      }
    },
    prevPage() {
      //@ts-ignore
      if (this.pagy.page != 1 && this.pagy.page != 0) {
        this.pagyConfig.page = this.pagyConfig.page - 1;
      }
    }
  },
  data() {
    return {
      options: [10, 20, 50, 100],
      pagyConfig: {
        per_page: 20,
        page: 1
      }
    };
  }
});