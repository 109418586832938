import axios from 'axios';
import store from '@/store';
import { message } from 'ant-design-vue';
import i18n from '@/plugin/i18n';
import Cookies from 'js-cookie';
const {
  t
} = i18n.global;
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: false,
  timeout: 60000
});
request.defaults.headers.put['Content-Type'] = 'application/json';
request.defaults.headers.put['Accept'] = '*/*';
request.interceptors.request.use(request => {
  let access_token = 'access_token';
  store.dispatch('addRequest');
  if (get(request, 'params.tab')) {
    delete request.params.tab;
  }
  if (Cookies.get('watch-2-earn')) {
    access_token = get(JSON.parse(Cookies.get('watch-2-earn')), 'auth.access_token');
  }
  request.headers.Authorization = 'Bearer ' + access_token;
  return request;
});
// Response interceptor for API calls
request.interceptors.response.use(response => {
  store.dispatch('removeRequest');
  return response;
}, async function (error) {
  store.dispatch('removeRequest');
  const originalRequest = error.config;
  if (get(error, 'response.status', '') === 401 && get(error, 'response.data.error_code') == 'TOKEN_EXPIRED') {
    if (get(originalRequest, 'url') != '/bo/api/v1/refresh_token') {
      try {
        await store.dispatch('auth/refreshToken');
        return request(originalRequest);
      } catch (e) {
        await store.dispatch('auth/logout');
        return Promise.reject(error);
      }
    } else {
      await store.dispatch('auth/logout');
    }
  } else if (get(error, 'response.status', '') === 401) {
    await store.dispatch('auth/logout');
  } else if (get(error, 'response.status', '') === 400) {
    message.error(get(error, 'response.data.msg', ''));
  } else if (get(error, 'response.status', '') === 422) {
    message.error(get(error, 'response.data.msg', ''));
  } else if (get(error, 'response.status', '') === 413) {
    message.error(get(error, 'response.data.msg', ''));
  } else if (get(error, 'response.status', '') === 404) {
    message.error(t('axios.404'));
  } else if (get(error, 'response.status', '') === 429) {
    message.error(t('axios.429'));
  } else {
    message.error(t('axios.500'));
  }
  return Promise.reject(error);
});
export default request;