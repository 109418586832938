import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import Cookies from 'js-cookie';
const CookieAuth = Cookies.get(process.env.VUE_APP_KEY);
const state = {
  access_token: CookieAuth ? get(JSON.parse(CookieAuth), 'auth.access_token', '') : '',
  refresh_token: CookieAuth ? get(JSON.parse(CookieAuth), 'auth.refresh_token', '') : '',
  username: CookieAuth ? get(JSON.parse(CookieAuth), 'auth.username', '') : ''
};
const auth = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
export default auth;