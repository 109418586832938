import { LocationQueryValue, RouteRecordRaw } from 'vue-router'
import { Commit, Module } from 'vuex'
import { TableRowSelection } from 'ant-design-vue/es/table/interface'

export interface Menu {
  title: string
  icon?: string
  router: string
  type?: 'single' | 'multiple'
  children?: Menu[]
}

export interface ModuleInterface {
  stores?: Record<string, Module<any, any>>
  router: RouteRecordRaw[]
}

export interface PagyInterface {
  total: number
  per_page: number
  page: number
  pages: number
  items: number
  next_page: number
  prev_page: number
  from: number
  to: number
}

export interface LoginInterface {
  username: string
  password: string
  remember?: boolean
}

export interface RefreshTokenInterface {
  username: string
  refresh_token: string
}

export enum Filter {
  SELECT_LOAD_MORE = 'select_load_more',
  NUMBER = 'number',
  TEXT = 'text',
  RANGE_DATE = 'range_date',
  RANGE_DATE_TIME = 'range_date_time',
  ARRAY = 'array',
  ORDER = 'order',
  SELECT_OPTIONS = 'select_options'
}

export interface FilterOptionInterface {
  type: Filter
  show?: boolean
  defaultParam?: boolean
  value: any
  title?: string
  more_data?: Record<string, any>
  options?: object
  placeholder?: Record<string, any>
  clear?: boolean
  format ?: string
}

export interface OrderFilterInterface {
  order: 'ascend' | 'descend' | undefined | null
  field: string
  index: number
}

export interface ResponseInterface {
  data: Record<string, any>[]
  meta: PagyInterface
}

export interface GroupFilterInterface {
  response: ResponseInterface
  page: FilterOptionInterface
  per_page: FilterOptionInterface

  [x: string]: FilterOptionInterface | ResponseInterface
}

export interface CommitFunction {
  commit: Commit
}

export interface CommitStateFunction extends CommitFunction {
  state: StateReset
}

export interface StateReset {
  stateDefault?: Record<string, any>

  [x: string]: any
}

export interface ParamsLoadMore {
  key: string
  value?: string[] | number[]
  defaultValue?: string[] | number[]
  filter?: string
  data?: Record<string, any>[]
}

export interface OptionSelect {
  config: {
    url: string
    option: {
      value: string
      title: string
    }
  }
  data: Record<string, any>[]
  pagy: PagyInterface
  related?: Record<
    string,
    {
      data: Record<string, any>[]
      pagy: PagyInterface
    }
  >
}

export interface CommitFunction {
  commit: Commit
}

export interface CommitStateFunction extends CommitFunction {
  state: StateReset
}

export interface ParamsPaginationInterface {
  page?: string | number | undefined | LocationQueryValue[]
  per_page?: string | number | undefined | LocationQueryValue[]
}

export interface ResponseRequestInterface {
  error: boolean
  msg: null | string

  [x: string]: Record<string, any> | boolean | string | null
}

export interface TableRowSelectionCustom extends TableRowSelection {
  typeSelect: 'one_page' | 'all_page' | 'id'
  exceptIds: number[]
}
