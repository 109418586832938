import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18ed021a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-left ml-16 mb-12" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 1,
  class: "first-letter"
}
const _hoisted_4 = {
  key: 4,
  class: "d-flex"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "select-promotion" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_TableAdmin = _resolveComponent("TableAdmin")!
  const _component_UpdateStreamer = _resolveComponent("UpdateStreamer")!
  const _component_base_confirm = _resolveComponent("base-confirm")!
  const _component_base_select = _resolveComponent("base-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_checkbox, {
        checked: _ctx.$store.state.stream.batchUpdate.type == 'all_page' && _ctx.$store.state.stream.batchUpdate.except_ids.length == 0,
        indeterminate: _ctx.$store.state.stream.batchUpdate.type == 'all_page' && _ctx.$store.state.stream.batchUpdate.except_ids.length != 0,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSelectData('all_page')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" All streamer ")
        ]),
        _: 1
      }, 8, ["checked", "indeterminate"])
    ]),
    _createVNode(_component_TableAdmin, {
      "row-selection": _ctx.rowSelection,
      module: "streamer",
      columns: _ctx.columns,
      ref: "template"
    }, {
      bodyCell: _withCtx(({ column, record }) => [
        (column.key === 'streamer_name')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("a", {
                href: "javascript:void(0)",
                onClick: ($event: any) => (_ctx.rowClick(record))
              }, _toDisplayString(record.streamer_name), 9, _hoisted_2),
              (record.is_partner)
                ? (_openBlock(), _createBlock(_component_base_icon, {
                    key: 0,
                    name: "partner",
                    size: "20"
                  }))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        (column.key === 'status' || column.key === 'current_streaming_status')
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(record[column.key]), 1))
          : _createCommentVNode("", true),
        (column.key === 'streamer')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createTextVNode(_toDisplayString(_ctx.$filters.get(record.streamer, 'streamer_name')), 1)
            ], 64))
          : _createCommentVNode("", true),
        (column.key === 'last_disabled_at' || column.key === 'last_enabled_at')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              _createTextVNode(_toDisplayString(_ctx.$filters.formatDatetime(record[column.key])), 1)
            ], 64))
          : _createCommentVNode("", true),
        (column.key === 'action')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (record.status == 'enabled')
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 0,
                    type: "danger flex-1",
                    onClick: ($event: any) => (_ctx.selectedRecord(record))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('module.stream.stop_watch_2_earn')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : (_openBlock(), _createBlock(_component_a_button, {
                    key: 1,
                    type: "success flex-1",
                    onClick: ($event: any) => (_ctx.selectedRecord(record))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('module.stream.start_watch_2_earn')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])),
              _createElementVNode("div", {
                class: "action",
                onClick: ($event: any) => (_ctx.editStreamer(record))
              }, _toDisplayString(_ctx.$t('common.edit')), 9, _hoisted_5)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["row-selection", "columns"]),
    _createVNode(_component_UpdateStreamer, {
      ref: "update",
      onReloadPage: _ctx.reload
    }, null, 8, ["onReloadPage"]),
    _createVNode(_component_base_confirm, {
      onOk: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateRecord(_ctx.recordSelected))),
      ref: "confirm"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: 
        _ctx.$t('message.change_status', {
          type: _ctx.$t('module.stream.streamer'),
          name: _ctx.recordSelected.streamer_name,
          from: _ctx.$t('common.' + _ctx.recordSelected.status),
          to: _ctx.$t('common.' + (_ctx.recordSelected.status == 'enabled' ? 'disabled' : 'enabled'))
        })
      
        }, null, 8, _hoisted_6)
      ]),
      _: 1
    }, 512),
    _withDirectives(_createElementVNode("div", _hoisted_7, [
      _createVNode(_component_base_select, { "use-load-more": {
            active: true,
            url: '/bo/api/v1/rewards',
            key: 'rewards',
            firstLoader: true,
            option: {
              search: 'search_text',
              value: 'id',
              title: 'djt_amount_per_hour'
            }
          } })
    ], 512), [
      [_vShow, false]
    ])
  ], 64))
}