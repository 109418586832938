import "core-js/modules/es.array.push.js";
import BaseTable from '@/components/base/BaseTable.vue';
import ReportRepository from '@/services/repositories/report';
import moment from 'moment';
const reportRepository = new ReportRepository();
export default defineComponent({
  name: 'ReportView',
  components: {
    BaseTable
  },
  data() {
    return {
      columns: [{
        title: this.$t('module.report.daily.date'),
        dataIndex: 'date',
        width: '120px',
        key: 'date'
      }, {
        title: this.$t('module.report.daily.total_viewers_count'),
        dataIndex: 'total_viewer_count',
        key: 'total_viewer_count',
        number: true
      }, {
        title: this.$t('module.report.daily.peak_viewers_count'),
        dataIndex: 'peak_viewer_count',
        key: 'peak_viewer_count',
        number: true
      }, {
        title: this.$t('module.report.daily.total_view_time'),
        dataIndex: 'total_view_time',
        key: 'total_view_time'
      }, {
        title: this.$t('module.report.daily.total_djt_claimed_viewers'),
        dataIndex: 'total_djt_claimer_count',
        key: 'total_djt_claimer_count',
        number: true
      }, {
        title: this.$t('module.report.daily.total_djt_claim_count'),
        dataIndex: 'total_djt_claim_count',
        key: 'total_djt_claim_count',
        number: true
      }, {
        title: this.$t('module.report.daily.total_djt_amount_claimed'),
        dataIndex: 'total_djt_amount_claimed',
        key: 'total_djt_amount_claimed',
        number: true
      }, {
        title: this.$t('module.report.daily.streamer_count'),
        dataIndex: 'streamer_count',
        key: 'streamer_count',
        number: true
      }, {
        title: this.$t('module.report.daily.stream_count'),
        dataIndex: 'stream_count',
        key: 'stream_count',
        number: true
      }],
      data: {
        data: [],
        meta: {
          from: 0,
          to: 0,
          total: 0,
          per_page: 20,
          page: 1
        }
      },
      loading: false
    };
  },
  methods: {
    updateParams() {
      const query = {
        page: undefined,
        per_page: undefined
      };
      if (this.data.meta.page != 1) {
        query.page = this.data.meta.page;
      }
      if (this.data.meta.per_page != 20) {
        query.per_page = this.data.meta.per_page;
      }
      let queryCustom = Object.assign({}, this.$route.query, query);
      this.$router.push({
        query: queryCustom
      });
    },
    moment,
    async getData(payload = {}) {
      this.loading = true;
      try {
        const result = await reportRepository.reportDaily(payload);
        this.data = result.daily_reports;
        this.updateParams();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    }
  },
  created() {
    this.getData({
      page: this.$route.query.page,
      per_page: this.$route.query.per_page
    });
  }
});