import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import 'ant-design-vue/dist/antd.variable.css'
import './assets/style/main.scss'
import Antd from 'ant-design-vue'
import components from '@/components/base/index'
import modules from '@/modules'
import i18n from '@/plugin/i18n'
import { forEach } from 'lodash'
import filters from '@/utils/filters'

const app = createApp(App)
//bind filter global
app.config.globalProperties.$filters = filters
//bind component global
for (const appKey in components) {
  app.component(appKey, components[appKey])
}

//bind module
forEach(modules, module => {
  // register route
  forEach(module.router, value => {
    router.addRoute(value)
  })

  // register store
  forEach(module.stores, (value, key) => {
    store.registerModule(key, value)
  })
})
app.use(router)
app.use(store)
app.use(Antd)
app.use(i18n)

app.mount('#app')
