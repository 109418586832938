const menus = [{
  type: 'single',
  title: 'menu.member',
  icon: 'user',
  router: '/user'
},
/*   {
title: 'menu.kyc',
icon: 'kyc',
router: '/kyc'
},
{
title: 'menu.bonus',
icon: 'bonus',
router: '/bonus'
},*/
{
  type: 'single',
  title: 'menu.stream',
  icon: 'stream',
  router: '/stream'
}, {
  type: 'multiple',
  title: 'menu.report',
  icon: 'chart',
  router: '/reports',
  children: [{
    title: 'menu.daily_report',
    router: '/reports/daily-report'
  }, {
    title: 'menu.monthly_report',
    router: '/reports/monthly-report'
  }, {
    title: 'menu.s2e_report',
    router: '/reports/s2e-report'
  }, {
    title: 'menu.user_report',
    router: '/reports/user-report'
  }]
}, {
  type: 'single',
  title: 'menu.pool_bet',
  icon: 'es',
  router: '/pool-bet'
}];
export default defineComponent({
  data() {
    return {
      menus: menus,
      selectedKeys: ['']
    };
  },
  computed: {
    openKeys() {
      return menus.filter(item => {
        return item.type == 'multiple';
      }).map(item => item.router);
    }
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(newVal) {
        const routeMatch = this.menus.filter(item => {
          return newVal.fullPath.startsWith(item.router);
        });
        if (!isEmpty(routeMatch)) {
          const lastItem = routeMatch[routeMatch.length - 1];
          this.selectedKeys[0] = lastItem.router;
          if (lastItem.children) {
            const routeSubMatch = lastItem.children.filter(item => {
              return newVal.fullPath.startsWith(item.router);
            });
            if (!isEmpty(routeSubMatch)) {
              const lastItem = routeSubMatch[routeSubMatch.length - 1];
              this.selectedKeys[1] = lastItem.router;
            }
          }
        }
      }
    }
  }
});