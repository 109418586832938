import { ModuleInterface } from '@/utils/interface/common'
import router from './router'
import store from './store'

const poolBetModule: ModuleInterface = {
  router: router,
  stores: store
}

export default poolBetModule
