import "core-js/modules/es.array.push.js";
import TableAdmin from '@/components/share/TableAdmin.vue';
import UpdateStreamer from '@/modules/stream/components/UpdateStreamer.vue';
import StreamerRepository from '@/services/repositories/streamer';
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import { cloneDeep, get, uniq } from 'lodash';
const apiStreamer = new StreamerRepository();
export default defineComponent({
  name: 'StreamerList',
  components: {
    BaseConfirm,
    TableAdmin,
    UpdateStreamer
  },
  setup() {
    const store = useStore();
    const template = ref();
    const onSelectMultiple = (selected, selectedRows, changeRows) => {
      const dataBatchUpdate = cloneDeep(get(store, 'state.stream.batchUpdate'));
      const ids = changeRows.map(item => {
        return item.id;
      });
      if (get(store, 'state.stream.batchUpdate.type') === 'all_page') {
        if (selected) {
          dataBatchUpdate.except_ids = dataBatchUpdate.except_ids.filter(item => {
            return !ids.includes(item);
          });
        } else {
          dataBatchUpdate.except_ids = uniq(dataBatchUpdate.except_ids.concat(ids));
        }
      } else {
        if (selected) {
          dataBatchUpdate.ids = uniq(dataBatchUpdate.ids.concat(ids));
        } else {
          dataBatchUpdate.ids = dataBatchUpdate.ids.filter(item => {
            return !ids.includes(item);
          });
        }
        dataBatchUpdate.total = dataBatchUpdate.ids.length;
      }
      store.dispatch('stream/setBatchUpdate', dataBatchUpdate);
    };
    const onSelect = (data, status) => {
      const dataBatchUpdate = cloneDeep(get(store, 'state.stream.batchUpdate'));
      if (get(store, 'state.stream.batchUpdate.type') === 'all_page') {
        if (status) {
          dataBatchUpdate.except_ids = dataBatchUpdate.except_ids.filter(item => {
            return item != data.id;
          });
        } else {
          dataBatchUpdate.except_ids.push(data.id);
          dataBatchUpdate.except_ids = uniq(dataBatchUpdate.except_ids);
        }
      } else {
        if (!status) {
          dataBatchUpdate.ids = dataBatchUpdate.ids.filter(item => {
            return item != data.id;
          });
        } else {
          dataBatchUpdate.ids.push(data.id);
        }
        dataBatchUpdate.total = dataBatchUpdate.ids.length;
      }
      store.dispatch('stream/setBatchUpdate', dataBatchUpdate);
    };
    const handleSelectData = type => {
      let dataBatchUpdate = {};
      if (get(store, 'state.stream.batchUpdate.type') == type) {
        dataBatchUpdate = {
          type: 'id',
          ids: [],
          except_ids: [],
          total: 0
        };
      } else {
        dataBatchUpdate = {
          type: type,
          ids: [],
          except_ids: [],
          total: get(template, 'value.pagy.total', 0)
        };
      }
      store.dispatch('stream/setBatchUpdate', dataBatchUpdate);
    };
    const rowSelection = computed(() => {
      return {
        typeSelect: get(store, 'state.stream.batchUpdate.type', []),
        exceptIds: get(store, 'state.stream.batchUpdate.except_ids', []),
        selectedRowKeys: get(store, 'state.stream.batchUpdate.ids', []),
        onSelect: onSelect,
        onSelectAll: onSelectMultiple
      };
    });
    return {
      handleSelectData,
      template,
      rowSelection
    };
  },
  data() {
    return {
      recordSelected: {},
      columns: [{
        title: this.$t('module.stream.table.streamer_name'),
        dataIndex: 'streamer_name',
        key: 'streamer_name',
        fixed: 'left'
      }, {
        title: this.$t('module.stream.table.streamer_twitch_id'),
        dataIndex: 'twitch_login',
        key: 'twitch_login'
      }, {
        title: this.$t('module.stream.table.streamer_youtube_channel_name'),
        dataIndex: 'youtube_channel_name',
        key: 'youtube_channel_name'
      }, {
        title: this.$t('module.stream.table.watch_2_earn_status'),
        dataIndex: 'status',
        key: 'status'
      }, {
        title: this.$t('module.stream.table.watch_2_earn_start_time'),
        dataIndex: 'last_enabled_at',
        key: 'last_enabled_at'
      }, {
        title: this.$t('module.stream.table.watch_2_earn_end_time'),
        dataIndex: 'last_disabled_at',
        key: 'last_disabled_at'
      }, {
        title: this.$t('module.stream.table.stream_status'),
        dataIndex: 'current_streaming_status',
        key: 'current_streaming_status'
      }, {
        title: this.$t('module.stream.table.total_stream_count'),
        dataIndex: 'total_stream_count',
        key: 'total_stream_count',
        number: true
      }, {
        title: this.$t('module.stream.table.total_stream_time'),
        dataIndex: 'total_stream_time_format',
        key: 'total_stream_time_format'
      }, {
        title: this.$t('module.stream.table.total_djt_claimed'),
        dataIndex: 'total_djt_claimed',
        key: 'total_djt_claimed',
        number: true
      }, {
        title: this.$t('module.stream.table.action'),
        dataIndex: 'action',
        width: 300,
        key: 'action',
        fixed: 'right'
      }]
    };
  },
  created() {
    this.$store.dispatch('stream/setAffiliateList');
  },
  methods: {
    selectedRecord(record) {
      this.recordSelected = record;
      this.$refs.confirm.show();
    },
    async updateRecord(record) {
      await apiStreamer.updateStatus(record.url_name);
      this.reload();
    },
    editStreamer(record) {
      this.$refs.update.show(record);
    },
    reload() {
      //@ts-ignore
      this.$refs.template.fetchData({
        page: this.$route.query.page,
        per_page: this.$route.query.per_page
      });
    },
    rowClick(record) {
      this.$router.push(`streamer/${record.url_name}`);
    }
  }
});