import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import NotFound from '@/components/share/NotFound.vue';
const routes = [{
  path: '/',
  name: 'home',
  redirect: '/stream'
}, {
  path: '/:catchAll(.*)',
  name: 'NotFound',
  component: NotFound
}];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    // always scroll to top
    return {
      top: 0,
      behavior: 'smooth'
    };
  },
  routes
});
router.beforeEach(async (to, from, next) => {
  if (to.meta?.title) {
    document.title = to.meta?.title + ' | ' + 'Watch 2 Earn';
  } else {
    document.title = 'Watch 2 Earn';
  }
  if (to.meta?.isNotLogin) {
    next();
    return;
  }
  if (!get(store, 'state.auth.access_token')) {
    next({
      name: 'Login'
    });
  }
  next();
});
export default router;