import { PlusOutlined } from '@ant-design/icons-vue';
import StreamerRepository from '@/services/repositories/streamer';
const apiStreamer = new StreamerRepository();
export default defineComponent({
  components: {
    PlusOutlined
  },
  props: {
    defaultValue: {
      type: [String, Array],
      require: false,
      default: () => []
    },
    name: {
      type: [String],
      require: false,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      validateMessages: {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!'
        },
        number: {
          range: '${label} must be between ${min} and ${max}'
        }
      },
      promotion: {
        id: '',
        remark: ''
      }
    };
  },
  methods: {
    handleCreatePromotion() {
      this.visible = true;
      this.$emit('update:value', '');
    },
    async onSubmitPromotion() {
      this.loading = true;
      await this.$refs.formPromotion.validate().then(async valid => {
        const data = await apiStreamer.createPromotion(valid);
        this.$emit('update:value', data.promotion.id);
        //@ts-ignore
        this.$store.dispatch('addOptions', {
          key: 'promotions',
          data: valid
        });
        this.visible = false;
        this.loading = false;
      }).catch(e => {
        this.loading = false;
      });
      this.loading = false;
    }
  }
});