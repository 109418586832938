import filters from '@/utils/filters';
export default defineComponent({
  components: {},
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  methods: {
    customValue(type, value) {
      switch (type) {
        case 'number':
          return filters.formatNumberDot(value >= 0 ? value : 0);
        case 'secondToTime':
          return filters.convertSecondToTime(value) || '-';
        case 'USDT':
          return filters.formatUSDT(value >= 0 ? value : 0);
        default:
          return value;
      }
    }
  }
});