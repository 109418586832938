import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { GroupFilterInterface, Filter } from '@/utils/interface/common'
import { defaultPagy } from '@/utils/common'

export interface PoolBet {
  queryPoolBetHistory: GroupFilterInterface
}

const statusOptions = [
  {
    value: 'pending',
    title: 'PENDING'
  },
  {
    value: 'won',
    title: 'WON'
  },
  {
    value: 'lost',
    title: 'LOST'
  },
  {
    value: 'cancelled',
    title: 'CANCELLED'
  }
]

const queryDefaultPoolBetHistory: GroupFilterInterface = {
  response: {
    meta: defaultPagy,
    data: []
  },
  page: {
    type: Filter.NUMBER,
    show: false,
    value: 1
  },
  tab: {
    type: Filter.TEXT,
    defaultParam: true,
    show: false,
    value: 'bet_history'
  },
  per_page: {
    type: Filter.NUMBER,
    show: false,
    value: 20
  },
  esb_user_id: {
    title: 'USER ID',
    type: Filter.TEXT,
    show: true,
    value: ''
  },
  created_at: {
    title: 'TIME',
    placeholder: ['Start time', 'End time'],
    type: Filter.RANGE_DATE_TIME,
    show: true,
    value: null,
    more_data: {
      utc: true
    }
  },
  bet_status: {
    title: 'BET STATUS',
    type: Filter.SELECT_OPTIONS,
    show: true,
    clear: true,
    value: null,
    options: statusOptions
  }
}
const state: PoolBet = {
  queryPoolBetHistory: queryDefaultPoolBetHistory
}

const poolBet: Module<PoolBet, unknown> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default poolBet
