import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_add_streamer = _resolveComponent("add-streamer")!
  const _component_batch_update_streamer = _resolveComponent("batch-update-streamer")!
  const _component_add_reward = _resolveComponent("add-reward")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_tabs, {
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _ctx.onActive
    }, {
      rightExtra: _withCtx(() => [
        (_ctx.activeKey == 'streamer')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.batchUpdate.total)
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 0,
                    type: "primary",
                    class: "btn-create mr-24",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$refs.formBatchUpdate.show()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('module.stream.batch_update', { total: _ctx.batchUpdate.total - _ctx.batchUpdate.except_ids.length })), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_a_button, {
                type: "primary",
                class: "btn-create",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$refs.formAdd.show()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('module.stream.create')), 1)
                ]),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.activeKey == 'reward')
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 1,
              type: "primary",
              class: "btn-create",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$refs.formReward.show()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('module.stream.add_reward')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
          return (_openBlock(), _createBlock(_component_a_tab_pane, {
            key: tab.key,
            tab: tab.title
          }, {
            default: _withCtx(() => [
              (_ctx.activeKey == tab.key)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(tab.component), {
                    key: 0,
                    ref_for: true,
                    ref: tab.key
                  }, null, 512))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["tab"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["activeKey", "onUpdate:activeKey"]),
    _createVNode(_component_add_streamer, {
      ref: "formAdd",
      onReloadPage: _cache[3] || (_cache[3] = ($event: any) => (_ctx.reloadPageStreamer()))
    }, null, 512),
    _createVNode(_component_batch_update_streamer, {
      ref: "formBatchUpdate",
      onReloadPage: _cache[4] || (_cache[4] = ($event: any) => (_ctx.reloadPageStreamer()))
    }, null, 512),
    _createVNode(_component_add_reward, { ref: "formReward" }, null, 512)
  ], 64))
}