import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e7b9bbcc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "clearfix" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_upload, {
      "file-list": _ctx.fileList,
      "onUpdate:file-list": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fileList) = $event)),
      action: _ctx.url,
      "list-type": "picture-card",
      onPreview: _ctx.handlePreview,
      name: "file_upload",
      onRemove: _ctx.removeFie,
      onChange: _ctx.handleChange,
      "before-upload": _ctx.beforeUpload,
      headers: { Authorization: 'Bearer ' + _ctx.$store.state.auth.access_token }
    }, {
      default: _withCtx(() => [
        (_ctx.hiddenImage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_base_icon, {
                name: "upload",
                size: "87"
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["file-list", "action", "onPreview", "onRemove", "onChange", "before-upload", "headers"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.previewVisible,
      title: _ctx.previewTitle,
      footer: null,
      onCancel: _ctx.handleCancel
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          alt: "upload-file",
          style: {"width":"100%"},
          src: _ctx.previewImage
        }, null, 8, _hoisted_3)
      ]),
      _: 1
    }, 8, ["visible", "title", "onCancel"])
  ]))
}