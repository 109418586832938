import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b7ac01aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "active"
}
const _hoisted_2 = { class: "icon-search" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterFilled = _resolveComponent("FilterFilled")!
  const _component_base_date = _resolveComponent("base-date")!
  const _component_base_select = _resolveComponent("base-select")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_UpOutlined = _resolveComponent("UpOutlined")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_ctx.active)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FilterFilled, {
            style: {fontSize: '20px', color: '#7A40EE', fontWeight: 'bold'},
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = !_ctx.show))
          })
        ]),
        _withDirectives(_createVNode(_component_a_form, {
          class: "form-filter",
          layout: "vertical",
          onSubmit: _ctx.handleSearch
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stateQuery, (item, key) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (key != 'response' && key != 'order' )
                  ? _withDirectives((_openBlock(), _createBlock(_component_a_form_item, {
                      key: key,
                      label: item.title
                    }, {
                      default: _withCtx(() => [
                        (item.type == 'range_date_time')
                          ? (_openBlock(), _createBlock(_component_base_date, {
                              key: 0,
                              value: item.value,
                              "onUpdate:value": ($event: any) => ((item.value) = $event),
                              "timezone-out": item.more_data.utc ? 'UTC' : 'current',
                              placeholder: item.placeholder,
                              type: "range_date_time",
                              format: item.format,
                              onChange: _ctx.changeData
                            }, null, 8, ["value", "onUpdate:value", "timezone-out", "placeholder", "format", "onChange"]))
                          : _createCommentVNode("", true),
                        (item.type == 'select_load_more')
                          ? (_openBlock(), _createBlock(_component_base_select, {
                              key: 1,
                              value: item.value,
                              "onUpdate:value": ($event: any) => ((item.value) = $event),
                              onChange: _ctx.changeData,
                              "default-value": item.value ? [item.value] : null,
                              "use-load-more": item.more_data ? item.more_data.useLoadMore : {}
                            }, null, 8, ["value", "onUpdate:value", "onChange", "default-value", "use-load-more"]))
                          : _createCommentVNode("", true),
                        (item.type == 'select_options')
                          ? (_openBlock(), _createBlock(_component_a_select, {
                              key: 2,
                              value: item.value,
                              "onUpdate:value": ($event: any) => ((item.value) = $event),
                              "allow-clear": item.clear
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (option, index) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    value: option.value,
                                    key: index
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(option.title), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["value", "onUpdate:value", "allow-clear"]))
                          : _createCommentVNode("", true),
                        (item.type == 'text')
                          ? (_openBlock(), _createBlock(_component_a_input, {
                              key: 3,
                              value: item.value,
                              "onUpdate:value": ($event: any) => ((item.value) = $event),
                              onChange: _ctx.changeData
                            }, null, 8, ["value", "onUpdate:value", "onChange"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["label"])), [
                      [_vShow, item.show]
                    ])
                  : _createCommentVNode("", true)
              ], 64))
            }), 256)),
            _createVNode(_component_a_form_item, { class: "group-btn" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  "html-type": "submit"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Search")
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_button, {
                  class: "btn-default",
                  onClick: _ctx.handleReset
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Reset filters, sorters")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createElementVNode("a", {
                  class: "collapse",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.show = false))
                }, [
                  _createVNode(_component_UpOutlined),
                  _createTextVNode(" Collapse ")
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onSubmit"]), [
          [_vShow, _ctx.show]
        ])
      ]))
    : _createCommentVNode("", true)
}