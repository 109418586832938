import { DefaultRepository } from '@/services/base'
import { StreamEntity } from '@/services/entities/stream'
import request from '@/plugin/request'
import { message } from 'ant-design-vue'
import i18n from '@/plugin/i18n'

const { t } = i18n.global
export default class StreamRepository extends DefaultRepository<StreamEntity> {
  constructor() {
    super('/bo/api/v1/streams')
  }

  async endStream(id: string | number): Promise<Record<string, any>> {
    try {
      const result = await request.post(`${this.prefix}/${id}/end_stream`)
      message.success(t('message.update_success'))
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
