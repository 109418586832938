import { RouteRecordRaw } from 'vue-router'
import DailyReport from '@/modules/report/views/DailyReport.vue'
import MonthlyReport from '@/modules/report/views/MonthlyReport.vue'
import S2eReportDetail from '@/modules/report/views/S2eReportDetail.vue'
import S2eReport from '@/modules/report/views/S2eReport.vue'
import UserReport from '@/modules/report/views/UserReport.vue'

const streamRoute: RouteRecordRaw[] = [
  {
    path: '/reports',
    redirect: '/daily-report',
    name: 'Reports',
    meta: {
      title: 'Reports'
    },
    children: [
      {
        path: '/reports/daily-report',
        component: DailyReport,
        name: 'DailyReport',
        meta: {
          title: 'Daily Report'
        }
      },
      {
        path: '/reports/monthly-report',
        component: MonthlyReport,
        name: 'MonthlyReport',
        meta: {
          title: 'Monthly Report'
        }
      },
      {
        path: '/reports/s2e-report',
        component: S2eReport,
        name: 'S2eReport',
        props: true,
        meta: {
          title: 'S2E Report'
        }
      },
      {
        path: '/reports/s2e-report/:url_name',
        component: S2eReportDetail,
        name: 'S2eReportDetail',
        props: true,
        meta: {
          title: 'S2E Report Details'
        }
      },
      {
        path: '/reports/user-report',
        component: UserReport,
        name: 'UserReport',
        props: true,
        meta: {
          title: 'User Report'
        }
      },
    ]
  }
]

export default streamRoute
