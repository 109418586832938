import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51a03a40"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-admin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormSearch = _resolveComponent("FormSearch")!
  const _component_base_table = _resolveComponent("base-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.haveStore)
      ? (_openBlock(), _createBlock(_component_FormSearch, {
          key: 0,
          store: _ctx.store
        }, null, 8, ["store"]))
      : _createCommentVNode("", true),
    _createVNode(_component_base_table, _mergeProps(_ctx.$attrs, {
      dataSource: _ctx.dataSource,
      columns: _ctx.columns,
      pagy: _ctx.pagy,
      loading: _ctx.loading || _ctx.$store.state.loading,
      onOnChangePagination: _ctx.fetchData,
      onOrderChange: _ctx.orderChange,
      class: "table-admin",
      order: _ctx.haveStore ? _ctx.stateQuery.order?.value : []
    }), _createSlots({ _: 2 }, [
      _renderList(_ctx.$slots, (slot, name) => {
        return {
          name: name,
          fn: _withCtx(({ column, record, index, text }) => [
            _renderSlot(_ctx.$slots, name, {
              record: record,
              column: column,
              index: index,
              text: text
            }, undefined, true)
          ])
        }
      })
    ]), 1040, ["dataSource", "columns", "pagy", "loading", "onOnChangePagination", "onOrderChange", "order"])
  ]))
}