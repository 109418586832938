import "core-js/modules/es.array.push.js";
import AuthRepository from '@/services/repositories/auth';
import { message } from 'ant-design-vue';
import i18n from '@/plugin/i18n';
import Cookies from 'js-cookie';
import router from '@/router';
const {
  t
} = i18n.global;
const SET_STATE = 'SET_STATE';
const SET_TOKEN = 'SET_TOKEN';
const apiAuth = new AuthRepository();
const actions = {
  async login({
    commit
  }, data) {
    try {
      const result = await apiAuth.login({
        username: data.username,
        password: data.password
      });
      const {
        access,
        refresh
      } = get(result, 'tokens', {
        access: '',
        refresh: ''
      });
      const info = {
        access_token: access,
        refresh_token: data.remember ? refresh : '',
        username: data.username
      };
      commit(SET_STATE, info);
      message.success(t('module.auth.sign_in.welcome'));
      return Promise.resolve(result);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async refreshToken({
    commit
  }) {
    try {
      const {
        username,
        refresh_token
      } = get(JSON.parse(Cookies.get('watch-2-earn')), 'auth', {
        username: '',
        refresh_token: ''
      });
      if (username && refresh_token) {
        const result = await apiAuth.refreshToken({
          username,
          refresh_token
        });
        const {
          access
        } = get(result, 'tokens', {
          access: ''
        });
        commit(SET_TOKEN, access);
      } else {
        const info = {
          access_token: '',
          refresh_token: '',
          username: ''
        };
        commit(SET_STATE, info);
        return Promise.reject();
      }
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async logout({
    commit
  }) {
    const info = {
      access_token: '',
      refresh_token: '',
      username: ''
    };
    commit(SET_STATE, info);
    router.push('/login');
    return Promise.reject();
  }
};
export default actions;