import moment from 'moment';
export const filters = {
  formatDatetime(time) {
    if (!time || time == '0001-01-01T00:00:00Z') return '';
    return moment(time).format('YYYY/MM/DD HH:mm');
  },
  formatDatetimeSecond(time) {
    if (!time || time == '0001-01-01T00:00:00Z') return '';
    return moment(time).format('YYYY/MM/DD HH:mm:ss');
  },
  get(data, path) {
    return get(data, path, '');
  },
  getByDefault(data, path, defaultValue) {
    const value = get(data, path, defaultValue);
    if (isEmpty(value)) return defaultValue;
  },
  formatNumberDot(value) {
    if (!value) return '0';
    const val = (value / 1).toFixed(0).replace('.', ',');
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  formatDate(time, format) {
    if (!time || time == '0001-01-01T00:00:00Z') return '';
    return moment(time).format(format ? format : 'YYYY-MM-DD');
  },
  formatMonthOfYear(time) {
    if (!time || time == '0001-01-01T00:00:00Z') return '';
    const currentDate = new Date(moment(time).format('YYYY/MM/DD'));
    const month = currentDate.toLocaleString('en', {
      month: "short"
    });
    const year = currentDate.getFullYear();
    return month + " " + year;
  },
  convertSecondToTime(time) {
    if (!time) return '00:00:00';
    function hhmmss(secs) {
      let minutes = Math.floor(secs / 60);
      secs = secs % 60;
      const hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
    }
    function pad(num) {
      if (num < 10) return '0' + num;
      return num;
    }
    return hhmmss(time);
  },
  formatUSDT(value) {
    const number = Number(Number(value).toFixed(5)).toString().split('.');
    const firstNumber = number[0];
    let lastNumber = number[1];
    if (lastNumber && lastNumber.length > 5) {
      lastNumber = lastNumber.slice(0, 5);
    }
    return this.formatNumberDot(firstNumber) + (lastNumber ? '.' + lastNumber : '');
  }
};
export default filters;