import MenuAdmin from '@/components/share/MenuAdmin.vue';
import HeaderAdmin from '@/components/share/HeaderAdmin.vue';
import Loading from '@/components/share/Loading.vue';
export default defineComponent({
  components: {
    MenuAdmin,
    HeaderAdmin,
    Loading
  },
  computed: {
    backLink() {
      const pathList = this.$route.path.split('/');
      pathList.pop();
      if (pathList.length >= 2) {
        if (pathList[1] === 'reports' && pathList[2] === 's2e-report' || ['stream', 'streamer', 'user'].includes(pathList[1])) {
          return pathList.join('/');
        }
        return '';
      } else {
        return '';
      }
    }
  },
  data() {
    return {
      collapsed: ref(false)
    };
  }
});