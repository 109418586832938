import { DefaultRepository } from '@/services/base'
import { ViewerEntity } from '@/services/entities/viewer'
import request from '@/plugin/request'

export default class ViewerRepository extends DefaultRepository<ViewerEntity> {
  constructor(params?: Record<string, any>) {
    super(`/bo/api/v1/streams/${params?.stream_id}/viewers`)
  }

  async userView(userId: string | number, payload: Record<string, any>): Promise<any> {
    try {
      const result = await request.get(`${this.prefix}/${userId}/events`, {
        params: payload
      })
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
