import StreamerRepository from '@/services/repositories/streamer';
import { createNamespacedHelpers } from 'vuex';
const apiStreamer = new StreamerRepository();
const streamMapper = createNamespacedHelpers('stream');
export default defineComponent({
  data() {
    return {
      loading: false,
      visible: false,
      validateMessages: {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!'
        },
        number: {
          range: '${label} must be between ${min} and ${max}'
        }
      },
      data: reactive({
        stream_reward_id: '',
        video_reward_id: '',
        status: 'enabled'
      })
    };
  },
  computed: {
    ...streamMapper.mapState(['batchUpdate'])
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      //@ts-ignore
      this.$refs.form.validate().then(async valid => {
        const payload = {
          type: this.batchUpdate.type == 'all_page' ? 'all' : 'id',
          ids: this.batchUpdate.type == 'all_page' ? [] : this.batchUpdate.ids,
          data: valid,
          except_ids: this.batchUpdate.except_ids
        };
        await apiStreamer.branchUpdate(payload);
        this.data = {
          stream_reward_id: '',
          video_reward_id: '',
          status: 'enabled'
        };
        this.visible = false;
        this.$emit('reloadPage');
      }).catch(e => {});
      this.loading = false;
    },
    show() {
      this.visible = true;
    }
  }
});