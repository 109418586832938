import { BaseRepositoryInterface, DefaultRepositoryInterface } from './index.d'
import request from '@/plugin/request'
import { message } from 'ant-design-vue'
import i18n from '@/plugin/i18n'

const { t } = i18n.global

export abstract class BaseRepository implements BaseRepositoryInterface {
  prefix: string

  protected constructor(prefix: string) {
    this.prefix = prefix
  }
}

export abstract class DefaultRepository<T> implements DefaultRepositoryInterface<T> {
  prefix: string

  protected constructor(prefix: string) {
    this.prefix = prefix
  }

  async all(payload: Record<string, any>): Promise<Array<Record<string, T>>> {
    try {
      const result = await request.get(`${this.prefix}`, {
        params: payload
      })
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async findById(id: string | number): Promise<T> {
    try {
      const result = await request.get(`${this.prefix}/${id}`)
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async findModuleRelation(id: string | number, relation: string, params: Record<string, any> = {}) {
    try {
      let url = `${this.prefix}/${id}`
      if (relation) {
        url += `/${relation}`
      }
      const result = await request.get(`${url}`, { params })
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async create(payload?: Record<string, any>): Promise<any> {
    try {
      const result = await request.post(`${this.prefix}`, payload)
      message.success(t('message.create_success'))
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async update(id: string | number, payload?: Record<string, any>): Promise<any> {
    try {
      const result = await request.patch(`${this.prefix}/${id}`, payload)
      message.success(t('message.update_success'))
      return Promise.resolve(result.data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async delete(id: string | number): Promise<any> {
    try {
      await request.delete(`${this.prefix}/${id}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
