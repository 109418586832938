import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, _mergeProps(_ctx.$attrs, _toHandlers(_ctx.$listeners)), {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$slots, (slot, name) => {
        return _renderSlot(_ctx.$slots, name)
      }), 256))
    ]),
    _: 3
  }, 16))
}