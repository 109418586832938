import TableAdmin from '@/components/share/TableAdmin.vue';
export default defineComponent({
  components: {
    TableAdmin
  },
  data() {
    return {
      columns: [{
        title: this.$t('module.report.user_report.table.esb_user_id'),
        dataIndex: 'esb_user_id',
        key: 'esb_user_id'
      }, {
        title: this.$t('module.report.user_report.table.username'),
        dataIndex: 'username',
        key: 'username'
      }, {
        title: this.$t('module.report.user_report.table.total_view_times'),
        dataIndex: 'total_view_times',
        key: 'total_view_times',
        secondToTime: true
      }, {
        title: this.$t('module.report.user_report.table.date'),
        dataIndex: 'date',
        key: 'date',
        date: true
      }]
    };
  }
});