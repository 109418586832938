import StreamRepository from './repositories/stream'
import StreamerRepository from './repositories/streamer'
import ViewerRepository from './repositories/viewer'
import UserRepository from '@/services/repositories/user'
import PoolBetRepository from './repositories/pool-bet'


export default function getRepository(name: string, params: Record<string, number | string> = {}) {
  switch (name) {
    case 'stream':
      return new StreamRepository()
    case 'streamer':
      return new StreamerRepository()
    case 'viewer':
      return new ViewerRepository(params)
    case 'user':
      return new UserRepository()
    case 'poolBet':
      return new PoolBetRepository()
    default:
      return new StreamRepository()
  }
}
