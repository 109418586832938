export default defineComponent({
  props: {
    name: {
      type: String,
      default: 'logo',
      require: true
    },
    disable: {
      type: Boolean,
      default: false,
      require: false
    },
    type: {
      type: String,
      default: 'svg',
      require: false
    },
    size: {
      type: [String, Number],
      default: null,
      require: false
    }
  },
  computed: {
    style() {
      if (this.size) {
        return 'width: ' + this.size + 'px; height: ' + this.size + 'px; font-size: ' + this.size;
      }
      return '';
    }
  },
  methods: {
    getSrc() {
      if (this.type == 'png') {
        return require('@/assets/image/png/' + this.name + '.png');
      }
    },
    className() {
      let text = '';
      text += this.disable ? 'disabled' : 'active';
      return text + ' svg-icon svg-icon--' + this.name;
    }
  },
  created() {
    require(`@/assets/image/svg/${this.name}.svg`);
  }
});