import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16cb2fa0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "form" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = { class: "text-custom-gradient" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_base_form_validator = _resolveComponent("base-form-validator")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('module.auth.sign_in.sign_in')), 1),
      _createVNode(_component_a_form, {
        class: "form-content",
        model: _ctx.form,
        onKeyup: _withKeys(_ctx.onSubmit, ["enter"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_base_form_validator, {
            name: "username",
            label: _ctx.$t('module.auth.sign_in.username')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.form.username,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.username) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_base_form_validator, {
            name: "password",
            label: _ctx.$t('module.auth.sign_in.password')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input_password, {
                value: _ctx.form.password,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.password) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_checkbox, {
              checked: _ctx.form.remember,
              "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.remember) = $event))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('module.auth.sign_in.remember_me')), 1)
              ]),
              _: 1
            }, 8, ["checked"])
          ]),
          _createVNode(_component_a_spin, { spinning: _ctx.loading }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: "button w-button",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
              }, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('module.auth.sign_in.sign_in')), 1)
              ])
            ]),
            _: 1
          }, 8, ["spinning"])
        ]),
        _: 1
      }, 8, ["model", "onKeyup"])
    ])
  ]))
}