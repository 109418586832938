import List from '@/modules/user/components/List.vue';
export default defineComponent({
  name: 'UserList',
  components: {
    List
  },
  props: {
    userId: {
      type: [String, Number],
      require: true
    }
  },
  data() {
    return {
      activeKey: 'user_list',
      tabs: [{
        key: 'user_list',
        component: 'List',
        title: this.$t('module.user.user_list')
      }]
    };
  },
  watch: {
    activeKey: {
      deep: true,
      immediate: true,
      handler() {
        this.$router.replace({
          query: {
            tab: this.activeKey
          }
        });
      }
    }
  },
  created() {
    this.activeKey = get(this.$route, 'query.tab', 'user_list');
  }
});