import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_base_form_validator = _resolveComponent("base-form-validator")!
  const _component_PromotionSelect = _resolveComponent("PromotionSelect")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    onOk: _ctx.onSubmit,
    onCancel: _ctx.onCancel,
    style: {"width":"680px"},
    visible: _ctx.visible,
    "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.visible) = $event)),
    confirmLoading: _ctx.loading,
    title: _ctx.$t('module.stream.add_reward'),
    "ok-text": _ctx.$t('common.add'),
    "cancel-text": _ctx.$t('common.cancel')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        model: _ctx.reward,
        "validate-messages": _ctx.validateMessages,
        ref: "form"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_base_form_validator, {
            label: _ctx.$t('module.stream.create_streamer.djt_per_hour'),
            name: "djt_amount_per_hour",
            rules: [{ required: true }, { type: 'number', min: 0 }, { type: 'number', max: 1000000 }]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input_number, {
                value: _ctx.reward.djt_amount_per_hour,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.reward.djt_amount_per_hour) = $event)),
                placeholder: "Enter amount of ESC earned per hour",
                "addon-after": "ESC",
                formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                parser: value => value.replace(/\$\s?|(,*)/g, '')
              }, null, 8, ["value", "formatter", "parser"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_base_form_validator, {
            label: _ctx.$t('module.stream.5_min_promotion'),
            name: "five_minutes_promotion_id",
            rules: [{ required: true }]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PromotionSelect, {
                ref: "promotion",
                name: "five_minutes_promotion_id",
                value: _ctx.reward.five_minutes_promotion_id,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.reward.five_minutes_promotion_id) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_base_form_validator, {
            label: _ctx.$t('module.stream.10_min_promotion'),
            name: "ten_minutes_promotion_id",
            rules: [{ required: true }]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PromotionSelect, {
                name: "ten_minutes_promotion_id",
                value: _ctx.reward.ten_minutes_promotion_id,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.reward.ten_minutes_promotion_id) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_base_form_validator, {
            label: _ctx.$t('module.stream.15_min_promotion'),
            name: "fifteen_minutes_promotion_id",
            rules: [{ required: true }]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PromotionSelect, {
                name: "fifteen_minutes_promotion_id",
                value: _ctx.reward.fifteen_minutes_promotion_id,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.reward.fifteen_minutes_promotion_id) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["model", "validate-messages"])
    ]),
    _: 1
  }, 8, ["onOk", "onCancel", "visible", "confirmLoading", "title", "ok-text", "cancel-text"]))
}