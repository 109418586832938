export default defineComponent({
  props: {
    amount: {
      type: [String, Number],
      default: 0
    },
    status: {
      type: String,
      default: null
    }
  }
});