import TableAdmin from '@/components/share/TableAdmin.vue';
import BaseIcon from '@/components/base/BaseIcon.vue';
export default defineComponent({
  components: {
    TableAdmin,
    BaseIcon
  },
  props: {
    streamerName: {
      type: [String],
      default: '',
      require: true
    }
  },
  computed: {
    streamer() {
      return get(this.response, 'streamer', {});
    }
  },
  data() {
    return {
      response: {},
      columns: [{
        title: this.$t('module.stream.stream_id'),
        dataIndex: 'stream_id',
        key: 'stream_id'
      }, {
        title: this.$t('module.stream.game_title'),
        dataIndex: 'game_name',
        key: 'game_name',
        ellipsis: true
      }, {
        title: this.$t('module.stream.stream_title'),
        dataIndex: 'title',
        key: 'title',
        ellipsis: true
      }, {
        title: this.$t('module.stream.table.total_view'),
        dataIndex: 'total_views',
        key: 'total_views',
        number: true
      }, {
        title: this.$t('module.stream.table.djt_claimed'),
        dataIndex: 'djt_claimed',
        key: 'djt_claimed',
        number: true
      }, {
        title: this.$t('module.stream.table.platform'),
        dataIndex: 'social_type',
        key: 'social_type'
      }]
    };
  }
});