import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-right mb-12" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_TableAdmin = _resolveComponent("TableAdmin")!
  const _component_AddApiYoutubeKey = _resolveComponent("AddApiYoutubeKey")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        type: "primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$refs.formAdd.show()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Add API key")
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_TableAdmin, {
      store: {state: 'queryYoutubeApiKey', action: 'searchYoutubeApiKey', module: 'setting'},
      scroll: 0,
      columns: _ctx.columns
    }, {
      bodyCell: _withCtx(({ column, record }) => [
        (column.key === 'type')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (record.type == 'youtube_data_api_v3' )
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Youtube api v3"))
                : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.columns.type), 1))
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columns"]),
    _createVNode(_component_AddApiYoutubeKey, { ref: "formAdd" }, null, 512)
  ], 64))
}