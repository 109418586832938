import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-638de260"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination d-flex justify-end" }
const _hoisted_2 = { class: "per-page" }
const _hoisted_3 = { class: "info-pagy" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_table, _mergeProps(_ctx.$attrs, {
      customRow: 
        record => {
          return {
            onClick: event => _ctx.$emit('rowClick', record, event) // click row
          }
        }
      ,
      loading: _ctx.loading,
      "data-source": _ctx.dataSource
    }, _toHandlers(_ctx.$listeners), {
      scroll: { x: _ctx.scroll },
      columns: _ctx.columnsCustom,
      "row-selection": _ctx.rowSelectionCustom,
      pagination: false,
      "row-key": "id",
      onChange: _ctx.handleChange
    }), _createSlots({ _: 2 }, [
      _renderList(_ctx.$slots, (slot, name) => {
        return {
          name: name,
          fn: _withCtx(({ column, record, index }) => [
            _renderSlot(_ctx.$slots, name, {
              record: record,
              column: column,
              index: index
            }, undefined, true)
          ])
        }
      })
    ]), 1040, ["customRow", "loading", "data-source", "scroll", "columns", "row-selection", "onChange"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.$t('base_table.row_per_page')) + ": ", 1),
        _createVNode(_component_a_select, {
          value: _ctx.pagyConfig.per_page,
          "onUpdate:value": _ctx.changeValue,
          style: {"width":"80px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
              return (_openBlock(), _createBlock(_component_a_select_option, {
                key: item,
                value: item
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["value", "onUpdate:value"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.pagy.from) + " - " + _toDisplayString(_ctx.pagy.to) + " " + _toDisplayString(_ctx.$t('common.of')) + " " + _toDisplayString(_ctx.$filters.formatNumberDot(_ctx.pagy.total)), 1)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["navigator", { disabled: _ctx.loading }])
      }, [
        _createVNode(_component_BaseIcon, {
          onClick: _ctx.prevPage,
          name: "left",
          disable: _ctx.pagy.page <= 1 || _ctx.loading
        }, null, 8, ["onClick", "disable"]),
        _createVNode(_component_BaseIcon, {
          onClick: _ctx.nextPage,
          name: "right",
          disable: _ctx.pagy.page == _ctx.pagy.pages || _ctx.loading
        }, null, 8, ["onClick", "disable"])
      ], 2)
    ])
  ]))
}