import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c0d6b69c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "confirm-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    onOk: _cache[1] || (_cache[1] = ($event: any) => (_ctx.visible = false)),
    class: "confirm",
    "ok-text": _ctx.$t('common.yes'),
    "cancel-text": _ctx.$t('common.no')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _: 3
  }, 8, ["visible", "ok-text", "cancel-text"]))
}