<template>
<component :is="layout">
  <router-view></router-view>
</component>
</template>

<script>
import AdminLayout from "@/components/layouts/AdminLayout";
import AuthLayout from "@/components/layouts/AuthLayout";
import {get} from "lodash"
export default {
name: "LayoutMain",
  components: {
    AuthLayout,
    AdminLayout
  },
  computed: {
    layout() {
      const route = this.$route
      if (!get(route, "meta.layout", ""))
        return "AdminLayout"
      else
        return "AuthLayout"
    }
  }
}
</script>

<style scoped>

</style>