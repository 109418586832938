import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a18c939"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-title" }
const _hoisted_2 = { class: "sub-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_table = _resolveComponent("base-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    style: {"width":"900px"},
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    footer: null
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('module.stream.view_history')), 1),
        _createTextVNode(" - "),
        _createElementVNode("span", null, _toDisplayString(_ctx.user.username), 1)
      ]),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('module.stream.table.stream_id')) + ": " + _toDisplayString(_ctx.stream.id), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_base_table, {
        loading: _ctx.loading,
        columns: _ctx.columns,
        dataSource: _ctx.userEventsData,
        pagy: _ctx.pagy,
        onOnChangePagination: _ctx.fetchUserEvent
      }, {
        bodyCell: _withCtx(({ column, record }) => [
          (column.key === 'category')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass({ [record.category]: true, badge: ['djt_claimed', 'djt_claim_available', 'djt_claim_expired'].includes(record.category) })
              }, _toDisplayString(_ctx.$t(`module.stream.viewer.table.${record.category}`)), 3))
            : _createCommentVNode("", true),
          (['djt_amount', 'djt_claimed_this_stream'].includes(column.key) && !['djt_claimed', 'djt_claim_available', 'djt_claim_expired'].includes(record.category))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(" - ")
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["loading", "columns", "dataSource", "pagy", "onOnChangePagination"])
    ]),
    _: 1
  }, 8, ["visible"]))
}