import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44cdd0de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = {
  key: 0,
  class: "suffix"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "card", {}, () => [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.customValue(_ctx.type, _ctx.value)), 1),
        (_ctx.suffix)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.suffix), 1))
          : _createCommentVNode("", true)
      ])
    ], true)
  ]))
}