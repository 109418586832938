import { ActionTree } from 'vuex'
import ReportRepository from '@/services/repositories/report'
import { IReport } from '.'

const actions: ActionTree<IReport, unknown> = {
  async setStreamerUrlName({ commit }, data) {
    commit('SET_STREAMER_URL_NAME', data)
  },
  async searchMonthlyReport({ commit, dispatch }) {
    try {
      dispatch('changeStateLoading', true, { root: true })
      const { dataSearch, dataOrigin, dataRoute } = await dispatch('convertDataSend', 'queryMonthlyReport')
      const Report = new ReportRepository()
      const result: Record<string, any> = await Report.reportMonthly(dataSearch)
      dataOrigin.response = result.monthly_reports
      commit('SET_STATE', { stateName: 'queryMonthlyReport', data: dataOrigin, query: dataRoute })
      dispatch('changeStateLoading', false, { root: true })
    } catch (e) {
      dispatch('changeStateLoading', false, { root: true })
      return Promise.reject(e)
    }
  },
  async searchS2eReport({ commit, dispatch }) {
    try {
      dispatch('changeStateLoading', true, { root: true })
      const { dataSearch, dataOrigin, dataRoute } = await dispatch('convertDataSend', 'queryS2eReport')
      const Report = new ReportRepository()
      const result: Record<string, any> = await Report.getS2eReport(dataSearch)
      dataOrigin.response = result.streamers
      commit('SET_STATE', { stateName: 'queryS2eReport', data: dataOrigin, query: dataRoute })
      dispatch('changeStateLoading', false, { root: true })
    } catch (e) {
      dispatch('changeStateLoading', false, { root: true })
      return Promise.reject(e)
    }
  },
  async searchS2eReportDetail({ commit, dispatch, state }) {
    try {
      dispatch('changeStateLoading', true, { root: true })
      const { dataSearch, dataOrigin, dataRoute } = await dispatch('convertDataSend', 'queryS2eReportDetail')
      const Report = new ReportRepository()
      const result: Record<string, any> = await Report.getS2eReportDetail(state.streamerUrlName, dataSearch)

      dataOrigin.response = result.reports
      commit('SET_STATE', { stateName: 'streamer', data: result.streamer })
      commit('SET_STATE', { stateName: 'queryS2eReportDetail', data: dataOrigin, query: dataRoute })
      dispatch('changeStateLoading', false, { root: true })
    } catch (e) {
      dispatch('changeStateLoading', false, { root: true })
      return Promise.reject(e)
    }
  },
  async searchUserReport({ commit, dispatch }) {
    try {
      dispatch('changeStateLoading', true, { root: true })
      const { dataSearch, dataOrigin, dataRoute } = await dispatch('convertDataSend', 'queryUserReport')
      const Report = new ReportRepository()
      const result: Record<string, any> = await Report.getUserReport(dataSearch)
      dataOrigin.response = result.streamers
      commit('SET_STATE', { stateName: 'queryUserReport', data: dataOrigin, query: dataRoute })
      dispatch('changeStateLoading', false, { root: true })
    } catch (e) {
      dispatch('changeStateLoading', false, { root: true })
      return Promise.reject(e)
    }
  }
}

export default actions
