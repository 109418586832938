import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';
import _, { cloneDeep } from 'lodash';
import request from '@/plugin/request';
import { defaultPagy } from '@/utils/common';
import router from '@/router';
import { decodeSearch, encodeSearch } from '@/utils/search';
const ADD_REQUEST = 'ADD_REQUEST';
const REMOVE_REQUEST = 'REMOVE_REQUEST';
const DISABLE_LOADING = 'DISABLE_LOADING';
const RESET_STATE = 'RESET_STATE';
const RESET_DATA_PAGE_STATE = 'RESET_DATA_PAGE_STATE';
const SET_STATE = 'SET_STATE';
export const enableResetStore = store => {
  return {
    ...store,
    state: {
      ...store.state,
      stateDefault: {
        ..._.cloneDeep(store.state)
      }
    },
    getters: {
      ...store.getters,
      dataFilter: state => queryName => {
        const stateFilter = state[queryName];
        const dataAccept = stateFilter.response;
        return dataAccept.data;
      },
      metaFilter: state => queryName => {
        const stateFilter = state[queryName];
        const dataAccept = stateFilter.response;
        return dataAccept.meta;
      }
    },
    actions: {
      resetData({
        commit
      }, stateName) {
        commit(RESET_STATE, stateName);
      },
      resetDataPage({
        commit
      }, stateName) {
        commit(RESET_DATA_PAGE_STATE, stateName);
      },
      convertDataSend({
        state
      }, stateName) {
        const dataOrigin = _.cloneDeep(state[stateName]);
        const dataDefault = _.get(state, 'stateDefault.' + stateName, {});
        const dataSearch = {};
        const dataRoute = {};
        for (const dataKey in dataOrigin) {
          const value = dataOrigin[dataKey].value;
          const valueEncode = encodeSearch(dataOrigin[dataKey].type, value);
          if (valueEncode) {
            dataSearch[dataKey] = valueEncode;
          }
          if (!_.isEqual(value, dataDefault[dataKey].value) || dataDefault[dataKey].defaultParam || valueEncode == 'start_time_desc') {
            dataRoute[dataKey] = valueEncode;
          }
        }
        return Promise.resolve({
          dataSearch,
          dataOrigin,
          dataRoute
        });
      },
      passDataFromQuery({
        commit,
        state
      }, params) {
        const cloneState = _.cloneDeep(state[params.stateName]);
        for (const routeKey in params.query) {
          // @ts-ignore
          if (routeKey in state.stateDefault[params.stateName] && !state.stateDefault[params.stateName][routeKey].defaultParam) {
            cloneState[routeKey].value = decodeSearch(cloneState[routeKey].type, _.get(params, `query.${routeKey}`), cloneState[routeKey].value);
          }
        }
        commit(SET_STATE, {
          stateName: params.stateName,
          data: cloneState
        });
      },
      ...store.actions
    },
    mutations: {
      ...store.mutations,
      RESET_STATE: (state, payload) => {
        state[payload] = _.cloneDeep(_.get(state, 'stateDefault.' + payload));
        const dataRoute = {};
        for (const dataKey in state[payload]) {
          const value = state[payload][dataKey].value;
          const valueEncode = encodeSearch(state[payload][dataKey].type, value);
          if (!_.isEqual(value, state[payload][dataKey].value) || state[payload][dataKey].defaultParam) {
            dataRoute[dataKey] = valueEncode;
          }
        }
        router.replace({
          query: dataRoute
        });
      },
      RESET_DATA_PAGE_STATE: (state, payload) => {
        const valueDefault = _.cloneDeep(_.get(state, 'stateDefault.' + payload));
        state[payload].page = valueDefault.page;
      },
      SET_STATE: (state, payload) => {
        if ('query' in payload) {
          router.replace({
            query: payload.query
          });
        }
        state[payload.stateName] = payload.data;
      }
    }
  };
};
const store = createStore({
  state: {
    requests: 0,
    options: {},
    loading: false
  },
  getters: {
    getOptions: state => ({
      key,
      filter
    }) => {
      if (filter) {
        //@ts-ignore
        return _.uniqBy(_.get(state.options, key + '.related.' + filter + '.data', []), 'value');
      } else {
        return _.uniqBy(_.get(state.options, key + '.data', []), 'value');
      }
    },
    getPagyOptions: state => ({
      key,
      filter
    }) => {
      if (!filter) {
        return _.cloneDeep(_.get(state.options, key + '.pagy', defaultPagy));
      } else {
        return _.cloneDeep(_.get(state.options, key + '.related.' + filter + '.pagy', defaultPagy));
      }
    }
  },
  mutations: {
    ADD_REQUEST(state) {
      state.requests++;
    },
    REMOVE_REQUEST(state) {
      state.requests--;
    },
    DISABLE_LOADING(state) {
      state.requests = 0;
    },
    SET_INIT_SELECT(state, params) {
      // @ts-ignore
      state.options[params.config.key] = params;
    },
    SET_DATA(state, params) {
      const config = _.get(state, 'options.' + params.key + '.config');
      const dataLoadMore = params.data.map(item => {
        return {
          value: item[config.option.value],
          title: item[config.option.title],
          data: item
        };
      });
      if (params.filter) {
        const dataOrigin = _.get(state, 'options.' + params.key + '.related.' + params.filter + '.data', []);
        _.set(state, 'options.' + params.key + '.related.' + params.filter + '.data', dataOrigin.concat(dataLoadMore));
      } else {
        if (params.firstPosition) {
          // @ts-ignore
          state.options[params.key].data = dataLoadMore.concat(state.options[params.key].data);
        } else {
          // @ts-ignore
          state.options[params.key].data = state.options[params.key].data.concat(dataLoadMore);
        }
      }
    },
    SET_PAGY(state, params) {
      if (params.filter) {
        const dataOrigin = _.get(state, 'options.' + params.key + '.related.' + params.filter + '.pagy', defaultPagy);
        _.set(state, 'options.' + params.key + '.related.' + params.filter + '.pagy', {
          ...dataOrigin,
          ...params.data
        });
      } else {
        // @ts-ignore
        state.options[params.key].pagy = params.data;
      }
    },
    CHANGE_LOADING(state, params) {
      state.loading = params;
    }
  },
  actions: {
    async resetDataOption({
      commit,
      state
    }, query) {
      // @ts-ignore
      const option = cloneDeep(state.options[query]);
      const OptionSelect = {
        config: option.config,
        data: [],
        related: {},
        pagy: defaultPagy
      };
      commit('SET_INIT_SELECT', OptionSelect);
    },
    async setInitDataSelect({
      commit,
      state
    }, config = {}) {
      // @ts-ignore
      if (isEmpty(state.options[config.key])) {
        const data = {
          config: config,
          data: [],
          related: {},
          pagy: defaultPagy
        };
        await commit('SET_INIT_SELECT', data);
        return Promise.resolve();
      }
    },
    async addOptions({
      commit
    }, params) {
      commit('SET_DATA', {
        key: params.key,
        data: [params.data],
        firstPosition: true
      });
    },
    async loadMoreOption({
      commit,
      state,
      getters
    }, params) {
      // @ts-ignore
      const config = state.options[params.key]?.config;
      if (!isEmpty(config)) {
        let dataOption = [];
        if (params.defaultValue) {
          // @ts-ignore
          const listValue = state.options[params.key].data.map(item => {
            return item.value;
          });
          // @ts-ignore
          const arrayCompare = _.difference(params.defaultValue, listValue);
          if (!isEmpty(arrayCompare)) {
            const result = await request.get(config.url, {
              params: {
                [config.option.value + '_in']: arrayCompare.join(',')
              }
            });
            dataOption = _.get(result, 'data.' + params.key + '.data', []);
          }
        }
        const pagy = getters.getPagyOptions({
          key: params.key
        });
        if (pagy.total > pagy.to) {
          const result = await request.get(config.url, {
            params: {
              page: pagy.page + 1,
              per_page: 200
            }
          });
          dataOption = dataOption.concat(_.get(result, 'data.' + params.key + '.data', []));
          const meta = _.get(result, 'data.' + params.key + '.meta', defaultPagy);
          commit('SET_DATA', {
            key: params.key,
            data: dataOption
          });
          commit('SET_PAGY', {
            key: params.key,
            data: meta
          });
        }
      }
    },
    async loadMoreOptionFilter({
      commit,
      state,
      getters
    }, params) {
      // @ts-ignore
      const config = state.options[params.key]?.config;
      if (!isEmpty(config)) {
        let dataOption = [];
        const pagy = getters.getPagyOptions({
          key: params.key,
          filter: params.filter
        });
        if (pagy.total > pagy.to) {
          const result = await request.get(config.url, {
            params: {
              page: pagy.page + 1,
              per_page: 200,
              [config.option.search ?? config.option.title]: params.filter
            }
          });
          dataOption = dataOption.concat(_.get(result, 'data.' + params.key + '.data', []));
          const meta = _.get(result, 'data.' + params.key + '.meta', defaultPagy);
          commit('SET_DATA', {
            key: params.key,
            data: dataOption,
            filter: params.filter
          });
          commit('SET_PAGY', {
            key: params.key,
            data: meta,
            filter: params.filter
          });
        }
      }
    },
    changeStateLoading({
      commit
    }, params) {
      commit('CHANGE_LOADING', params);
    },
    addRequest({
      commit
    }) {
      commit(ADD_REQUEST);
    },
    disableLoading({
      commit
    }) {
      commit(DISABLE_LOADING);
    },
    removeRequest({
      commit
    }) {
      commit(REMOVE_REQUEST);
    }
  },
  modules: {},
  plugins: [createPersistedState({
    key: 'watch-2-earn',
    paths: ['auth'],
    storage: {
      getItem: key => Cookies.get(key),
      setItem: (key, value) => Cookies.set(key, value, {
        expires: 365,
        secure: false
      }),
      removeItem: key => Cookies.remove(key)
    }
  })]
});
export default store;