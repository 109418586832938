import { ActionTree } from 'vuex'
import { IUser } from '.'
import UserRepository from '@/services/repositories/user'

const actions: ActionTree<IUser, unknown> = {
  async setUserId({ commit }, data) {
    commit('SET_USER_ID', data)
  },
  async searchLiveStreamHistory({ commit, dispatch, state }) {
    try {
      dispatch('changeStateLoading', true, { root: true })
      const { dataSearch, dataOrigin, dataRoute } = await dispatch('convertDataSend', 'queryHistoryLivestreamUser')
      const User = new UserRepository()
      const result: Record<string, any> = await User.getHistory(state.userId, dataSearch)
      dataOrigin.response = result.w2e_histories
      commit('SET_USER', result)
      commit('SET_STATE', { stateName: 'queryHistoryLivestreamUser', data: dataOrigin, query: dataRoute })
      dispatch('changeStateLoading', false, { root: true })
    } catch (e) {
      dispatch('changeStateLoading', false, { root: true })
      return Promise.reject(e)
    }
  },
  async searchPartVideoHistory({ commit, dispatch, state }) {
    try {
      dispatch('changeStateLoading', true, { root: true })
      const { dataSearch, dataOrigin, dataRoute } = await dispatch('convertDataSend', 'queryHistoryPartVideoUser')
      const User = new UserRepository()
      const result: Record<string, any> = await User.getHistory(state.userId, dataSearch)
      dataOrigin.response = result.streams
      commit('SET_STATE', { stateName: 'queryHistoryPartVideoUser', data: dataOrigin, query: dataRoute })
      dispatch('changeStateLoading', false, { root: true })
    } catch (e) {
      dispatch('changeStateLoading', false, { root: true })
      return Promise.reject(e)
    }
  },
  async searchUser({ commit, dispatch }) {
    try {
      dispatch('changeStateLoading', true, { root: true })
      const { dataSearch, dataOrigin, dataRoute } = await dispatch('convertDataSend', 'queryUser')
      const User = new UserRepository()
      const result: Record<string, any> = await User.all(dataSearch)
      dataOrigin.response = result.users
      commit('SET_STATE', { stateName: 'queryUser', data: dataOrigin, query: dataRoute })
      dispatch('changeStateLoading', false, { root: true })
    } catch (e) {
      dispatch('changeStateLoading', false, { root: true })
      return Promise.reject(e)
    }
  }
}

export default actions
