import StreamHistory from '@/modules/stream/components/StreamHistory.vue';
import PastStreamHistory from '@/modules/stream/components/PastStreamHistory.vue';
import _ from 'lodash';
const apiStreamer = getRepository('streamer');
export default defineComponent({
  name: 'DetailStreamer',
  components: {
    StreamHistory,
    PastStreamHistory
  },
  props: {
    streamerName: {
      type: [String, Number],
      require: true
    }
  },
  computed: {
    streamer() {
      return get(this.response, 'streamer', {});
    }
  },
  data() {
    return {
      response: {},
      activeKey: '',
      tabs: [{
        key: 'streamer_stream_history',
        component: 'StreamHistory',
        title: this.$t('module.stream.streamer_stream_history')
      }, {
        key: 'past_stream_history',
        component: 'PastStreamHistory',
        title: this.$t('module.stream.past_stream_history')
      }]
    };
  },
  async mounted() {
    this.response = await apiStreamer.findModuleRelation(this.streamerName, '', {
      page: this.$route.query.page,
      per_page: this.$route.query.per_page
    });
    _.set(this.$refs, 'streamer_history[0].$refs.table.response', _.get(this.response, 'streams', {}));
  },
  created() {
    this.activeKey = get(this.$route, 'query.tab', 'streamer_stream_history');
    this.$store.dispatch('stream/setUrlName', this.streamerName);
  },
  unmounted() {
    this.$store.dispatch('stream/resetData', 'queryStreamHistory');
    this.$store.dispatch('stream/resetData', 'queryPastStreamHistory');
  },
  methods: {
    async onActive(tab) {
      await this.$router.replace({
        query: {
          tab: tab
        }
      });
      this.activeKey = tab;
    },
    convertSecondsToTime(seconds) {
      const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
      const minutes = Math.floor(seconds % 3600 / 60).toString().padStart(2, '0');
      const secs = (seconds % 60).toString().padStart(2, '0');
      return `${hours}:${minutes}:${secs}`;
    }
  }
});