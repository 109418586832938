import PromotionSelect from '@/modules/stream/components/PromotionSelect.vue';
import RewardRepository from '@/services/repositories/reward';
const apiReward = new RewardRepository();
export default defineComponent({
  components: {
    PromotionSelect
  },
  data() {
    return {
      loading: false,
      visible: false,
      validateMessages: {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!'
        },
        number: {
          range: '${label} must be between ${min} and ${max}'
        }
      },
      reward: reactive({
        five_minutes_promotion_id: null,
        ten_minutes_promotion_id: null,
        fifteen_minutes_promotion_id: null,
        djt_amount_per_hour: null
      })
    };
  },
  methods: {
    async onCancel() {
      this.$refs.form.clearValidate();
    },
    async onSubmit() {
      this.loading = true;
      this.$refs.form.validate().then(async valid => {
        await apiReward.create(valid);
        this.reward = {
          five_minutes_promotion_id: null,
          ten_minutes_promotion_id: null,
          fifteen_minutes_promotion_id: null,
          djt_amount_per_hour: null
        };
        this.visible = false;
        this.$store.dispatch('stream/resetData', 'queryReward');
        this.$store.dispatch('resetDataOption', 'rewards');
        this.$store.dispatch('stream/searchReward');
      }).catch(e => {});
      this.loading = false;
    },
    show() {
      this.visible = true;
    }
  }
});