import ViewerRepository from '@/services/repositories/viewer';
export default defineComponent({
  components: {},
  props: {
    stream_id: {
      type: [String, Number],
      required: true,
      default: ''
    },
    user_id: {
      type: [String, Number],
      required: true,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      userId: '',
      response: {},
      columns: [{
        title: this.$t('module.stream.viewer.table.time'),
        dataIndex: 'created_at',
        key: 'created_at',
        datetimeSecond: true
      }, {
        title: this.$t('module.stream.viewer.table.event'),
        dataIndex: 'category',
        key: 'category'
      }, {
        title: this.$t('module.stream.viewer.table.djt_amount'),
        dataIndex: 'djt_amount',
        key: 'djt_amount',
        number: true
      }, {
        title: this.$t('module.stream.viewer.table.djt_claim'),
        dataIndex: 'djt_claimed_this_stream',
        key: 'djt_claimed_this_stream',
        number: true
      }]
    };
  },
  computed: {
    stream() {
      return get(this.response, 'stream', {});
    },
    user() {
      return get(this.response, 'user', {});
    },
    userEventsData() {
      return get(this.response, 'user_events.data', []);
    },
    pagy() {
      return get(this.response, 'user_events.meta', {});
    }
  },
  methods: {
    show(userId) {
      this.visible = true;
      this.userId = userId;
      this.fetchUserEvent({});
    },
    async fetchUserEvent(params) {
      try {
        this.loading = true;
        const api = new ViewerRepository({
          stream_id: this.stream_id
        });
        this.response = await api.userView(this.userId, params);
      } catch (e) {}
      this.loading = false;
    }
  }
});