import { ModuleInterface } from '@/utils/interface/common'
import Stream from '@/modules/stream'
import Auth from '@/modules/auth'
import User from '@/modules/user'
import Report from '@/modules/report'
import Setting from '@/modules/settings'
import PoolBet from '@/modules/pool-bet'

const modules: Record<string, ModuleInterface> = {
  Report,
  Stream,
  Auth,
  User,
  Setting,
  PoolBet
}

export default modules
