export default defineComponent({
  components: {},
  data() {
    return {
      visible: false,
      validateMessages: {
        required: '${label} is required'
      },
      formConfirm: reactive({
        url_name: '',
        twitch_login: '',
        streamer_name: '',
        profile_image_url: '',
        video_reward_id: '',
        stream_reward_id: '',
        description: '',
        status: 'enabled',
        default_category: '',
        is_partner: false,
        youtube_channel_id: '',
        s2e_token_amount_per_hour: null,
        s2e_token_amount_per_hour_for_video: null,
        affiliate: '',
        affiliate_stream_reward_id: '',
        affiliate_video_reward_id: '',
        passcode: ''
      })
    };
  },
  computed: {},
  methods: {
    async onCancel() {
      this.formConfirm.passcode = '';
      this.$refs.formConfirm.clearValidate();
    },
    async onSubmit() {
      //@ts-ignore
      this.$refs.formConfirm.validate().then(async valid => {
        this.formConfirm.passcode = valid.passcode;
        this.$emit('submit', this.formConfirm);
        this.$refs.formConfirm.clearValidate();
        this.visible = false;
      }).catch(e => {});
    },
    show(formData) {
      this.formConfirm = cloneDeep(formData);
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.inputCode.focus();
      });
    }
  }
});