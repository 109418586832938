import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b5939e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "cursor"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_TableAdmin = _resolveComponent("TableAdmin")!
  const _component_base_confirm = _resolveComponent("base-confirm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TableAdmin, {
      module: "stream",
      columns: _ctx.columns,
      ref: "template",
      store: { module: 'stream', state: 'queryLiveStream', action: 'searchLiveStream' }
    }, {
      bodyCell: _withCtx(({ column, record }) => [
        (column.key === 'stream_id')
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: `/stream/${record.id}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(record.id), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          : _createCommentVNode("", true),
        (column.key === 'status')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", {
                class: _normalizeClass(["badge", record.status])
              }, [
                _createElementVNode("span", null, _toDisplayString(record.status), 1)
              ], 2)
            ]))
          : _createCommentVNode("", true),
        (column.key === 'streamer')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createTextVNode(_toDisplayString(_ctx.$filters.get(record.streamer, 'streamer_name')) + " ", 1),
              (_ctx.$filters.get(record.streamer, 'is_partner', 'false') == 'true')
                ? (_openBlock(), _createBlock(_component_base_icon, {
                    key: 0,
                    name: "partner",
                    size: "20"
                  }))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        (column.key === 'start_time' || column.key === 'end_time')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              _createTextVNode(_toDisplayString(_ctx.$filters.formatDatetime(record[column.key])), 1)
            ], 64))
          : _createCommentVNode("", true),
        (column.key === 'action')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
              (record.status == 'live')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "action",
                    onClick: ($event: any) => (_ctx.selectRecord(record))
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('module.stream.table.end')), 1)
                  ], 8, _hoisted_2))
                : (_openBlock(), _createElementBlock("div", _hoisted_3))
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columns"]),
    _createVNode(_component_base_confirm, {
      ref: "confirm",
      onOk: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateRecord(_ctx.record)))
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: _ctx.$t('message.end_stream', { name: _ctx.record.streamer.streamer_name })
        }, null, 8, _hoisted_4)
      ]),
      _: 1
    }, 512)
  ], 64))
}