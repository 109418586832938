import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
export default defineComponent({
  components: {},
  props: {
    timezoneView: {
      type: String,
      require: false,
      default: 'current'
    },
    timezoneOut: {
      type: String,
      require: false,
      default: 'current'
    },
    format: {
      type: String,
      require: false,
      default: 'YYYY-MM-DD HH:mm'
    },
    type: {
      type: String,
      require: true,
      default: 'date',
      validate: value => {
        return ['date', 'range_date_time'].includes(value);
      }
    },
    value: {
      type: [String, Number, Array],
      require: false,
      default: null
    },
    placeholder: {
      type: Array,
      require: false,
      default: () => {
        return ['Start date', 'End date'];
      }
    }
  },
  computed: {
    isUTC() {
      return this.timezoneOut == 'UTC';
    },
    valueCustom() {
      if (!this.value) {
        return null;
      } else {
        if (this.type == 'range_date_time' && Array.isArray(this.value)) {
          const startTime = this.isUTC ? dayjs.utc(this.value[0]).tz().format(this.format) : this.value[0];
          const endTime = this.isUTC ? dayjs.utc(this.value[1]).tz().format(this.format) : this.value[1];
          return [startTime, endTime];
        }
      }
      return this.value;
    },
    component() {
      switch (this.type) {
        case 'range_date_time':
          return 'a-range-picker';
        default:
          return 'a-date-picker';
      }
    },
    showTime() {
      return this.format?.includes('HH:mm');
    }
  },
  methods: {
    handleChange(value) {
      if (Array.isArray(value)) {
        const startTime = this.isUTC ? dayjs(value[0]).utc().format(this.format) : value[0];
        const endTime = this.isUTC ? dayjs(value[1]).utc().format(this.format) : value[1];
        this.$emit('update:value', [startTime, endTime]);
      } else {
        this.$emit('update:value', value);
      }
    }
  },
  data() {
    return {
      response: {},
      data: [],
      textSearch: ''
    };
  }
});