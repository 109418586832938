import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6744fd4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "cursor"
}
const _hoisted_2 = {
  key: 3,
  class: "table-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_TableAdmin = _resolveComponent("TableAdmin")!

  return (_openBlock(), _createBlock(_component_TableAdmin, {
    module: "streamer",
    columns: _ctx.columns,
    scroll: { x: 0 },
    store: {
      module: 'stream',
      state: 'queryStreamHistory',
      action: 'searchStreamerStreamHistory'
    }
  }, {
    bodyCell: _withCtx(({ column, record }) => [
      (column.key === 'id')
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: `/stream/${record.id}`
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.id), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        : _createCommentVNode("", true),
      (column.key === 'status')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["badge", record.status])
            }, [
              _createElementVNode("span", null, _toDisplayString(record.status), 1)
            ], 2)
          ]))
        : _createCommentVNode("", true),
      (column.key === 'streamer')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createTextVNode(_toDisplayString(record.streamer.streamer_name), 1)
          ], 64))
        : _createCommentVNode("", true),
      (column.key === 'platform')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPlatforms(record.stream_platforms), (item) => {
              return (_openBlock(), _createBlock(_component_BaseIcon, {
                name: item.platform_type.toLowerCase(),
                key: item.id
              }, null, 8, ["name"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["columns"]))
}