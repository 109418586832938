import { ActionTree } from 'vuex'
import { IStream } from '.'
import StreamRepository from '@/services/repositories/stream'
import RewardRepository from '@/services/repositories/reward'
import StreamerRepository from '@/services/repositories/streamer'

const actions: ActionTree<IStream, unknown> = {
  setBatchUpdate({ commit }, payload: Record<string, any>) {
    commit('SET_BATCH_UPDATE', payload)
  },
  async searchLiveStream({ commit, dispatch }) {
    try {
      dispatch('changeStateLoading', true, { root: true })
      const { dataSearch, dataOrigin, dataRoute } = await dispatch('convertDataSend', 'queryLiveStream')
      const Stream = new StreamRepository()
      const result: Record<string, any> = await Stream.all(dataSearch)
      dataOrigin.response = result.streams
      commit('SET_STATE', { stateName: 'queryLiveStream', data: dataOrigin, query: dataRoute })
      dispatch('changeStateLoading', false, { root: true })
    } catch (e) {
      dispatch('changeStateLoading', false, { root: true })
      return Promise.reject(e)
    }
  },
  async searchLiveStreamHistory({ commit, dispatch }) {
    try {
      dispatch('changeStateLoading', true, { root: true })
      const { dataSearch, dataOrigin, dataRoute } = await dispatch('convertDataSend', 'queryLiveStreamHistory')
      const Stream = new StreamRepository()
      const result: Record<string, any> = await Stream.all(dataSearch)
      dataOrigin.response = result.streams
      commit('SET_STATE', { stateName: 'queryLiveStreamHistory', data: dataOrigin, query: dataRoute })
      dispatch('changeStateLoading', false, { root: true })
    } catch (e) {
      dispatch('changeStateLoading', false, { root: true })
      return Promise.reject(e)
    }
  },
  async searchReward({ commit, dispatch }) {
    try {
      dispatch('changeStateLoading', true, { root: true })
      const { dataSearch, dataOrigin, dataRoute } = await dispatch('convertDataSend', 'queryReward')
      const Reward = new RewardRepository()
      const result: Record<string, any> = await Reward.all(dataSearch)
      dataOrigin.response = result.rewards
      commit('SET_STATE', { stateName: 'queryReward', data: dataOrigin, query: dataRoute })
      dispatch('changeStateLoading', false, { root: true })
    } catch (e) {
      dispatch('changeStateLoading', false, { root: true })
      return Promise.reject(e)
    }
  },
  async searchStreamerStreamHistory({ commit, dispatch, state }) {
    try {
      dispatch('changeStateLoading', true, { root: true })
      const { dataSearch, dataOrigin, dataRoute } = await dispatch('convertDataSend', 'queryStreamHistory')
      const Streamer = new StreamerRepository()
      const result: Record<string, any> = await Streamer.streamerStreamHistories(state.urlName, dataSearch)
      dataOrigin.response = result.stream_histories
      commit('SET_STATE', { stateName: 'queryStreamHistory', data: dataOrigin, query: dataRoute })
      dispatch('changeStateLoading', false, { root: true })
    } catch (e) {
      dispatch('changeStateLoading', false, { root: true })
      return Promise.reject(e)
    }
  },
  async searchStreamerPastStreamHistory({ commit, dispatch, state }) {
    try {
      dispatch('changeStateLoading', true, { root: true })
      const { dataSearch, dataOrigin, dataRoute } = await dispatch('convertDataSend', 'queryPastStreamHistory')
      const Streamer = new StreamerRepository()
      const result: Record<string, any> = await Streamer.streamerPastStreamHistories(state.urlName, dataSearch)
      dataOrigin.response = result.video_histories

      commit('SET_STATE', { stateName: 'queryPastStreamHistory', data: dataOrigin, query: dataRoute })
      dispatch('changeStateLoading', false, { root: true })
    } catch (e) {
      dispatch('changeStateLoading', false, { root: true })
      return Promise.reject(e)
    }
  },
  setUrlName: ({ commit }, urlName: string) => {
    commit('SET_URL_NAME', urlName)
  },
  async setAffiliateList({ commit }) {
    const Streamer = new StreamerRepository()
    const result: Record<string, any> = await Streamer.affiliateList()
    const affiliates = result.data
    commit('SET_AFFILIATE_LIST', affiliates)
  }
}

export default actions
