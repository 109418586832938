import { DefaultRepository } from '@/services/base'
import { StreamerEntity } from '@/services/entities/streamer'
import request from '@/plugin/request'
import { message } from 'ant-design-vue'
import i18n from '@/plugin/i18n'

const { t } = i18n.global

export default class StreamerRepository extends DefaultRepository<StreamerEntity> {
  constructor() {
    super('/bo/api/v1/streamers')
  }

  async getChannelId(params: Record<string, any>): Promise<Record<string, any>> {
    try {
      const result = await request.get(`/bo/api/v1/youtubes/get_channel_id`, { params })
      message.success('Get channel id youtube success')
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateStatus(id: string): Promise<Record<string, any>> {
    try {
      const result = await request.patch(`${this.prefix}/${id}/change_status`)
      message.success(t('message.update_success'))
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async createPromotion(params: Record<string, any>): Promise<Record<string, any>> {
    try {
      const result = await request.post(`/bo/api/v1/promotions`, params)
      message.success(t('message.update_success'))
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async branchUpdate(payload: Record<string, any>): Promise<Record<string, any>> {
    try {
      const result = await request.post(`/bo/api/v1/streamers_batch_update`, payload)
      message.success(t('message.update_success'))
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async streamerStreamHistories(url_name: string, params: Record<string, any>): Promise<Record<string, any>> {
    try {
      const result = await request.get(`/bo/api/v1/streamers/${url_name}/stream_histories`, { params })
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async streamerPastStreamHistories(url_name: string, params: Record<string, any>): Promise<Record<string, any>> {
    try {
      const result = await request.get(`/bo/api/v1/streamers/${url_name}/video_histories`, { params })
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async affiliateList(): Promise<Record<string, any>> {
    try {
      const result = await request.get(`/bo/api/v1/s2e_affiliates`)
      return Promise.resolve(result.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
